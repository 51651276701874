import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import areaApi from 'api/area/areaApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Area } from 'core/model';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';
import { setRoutes } from 'scenes/dashboard/helpers/admin';

const AreaContainer = () => {
  const [loading, setLoading] = useState(true);
  const [areasRoutes, setAreasRoutes] = useState([]);
  const [args] = useState([]);
  const { fetched: fetchedAreas, pending: pendingAreas } = useApi(
    areaApi.getAll,
    args
  );

  useEffect(() => {
    const aRoutes = setRoutes(
      _.filter(fetchedAreas?.areas, (a) => a.key) as Area[],
      'admin/area',
      ':name?',
      'RiCheckboxMultipleBlankLine'
    );

    setAreasRoutes(aRoutes);
    setLoading(false);
  }, [fetchedAreas]);

  return loading || pendingAreas ? (
    <FwSpinner />
  ) : (
    <TilesPanel routes={areasRoutes} />
  );
};

export default AreaContainer;
