export enum MODAL_TYPES {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export const enum TRANSACTION_TYPES {
  create = 'CREATE',
  edit = 'EDIT',
}
