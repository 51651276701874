import _ from 'lodash';

import BaseApi, { BaseExternalApi } from 'api/baseApi';
import utils from 'core/utils/utils';

const api = (params) => (params && params.local ? BaseApi : BaseExternalApi);

const executeScript = (script: any, params: any) => {
  // create function from script
  const evalFunc = new Function(..._.keys(params), script);

  // evaluate
  const evalResult = evalFunc(..._.values(params));

  // return
  return evalResult;
};

const updateDataAndExecuteScript = (script, docData, key, value) => {
  // update docData if has changed
  if (key) {
    docData[key] = value;
  }

  return executeScript(script, { docData });
};

const applyScript = (rules, { doc, data, handler, ...params }) => {
  // prepare params
  const scriptParams = {
    ...params,
    api,
    callback: handler,
    docData: utils.getFullData(doc, data),
  };

  // loop
  _.forEach(rules, (r) => {
    // evaluate
    const result = executeScript(r.script.data, scriptParams);

    if (result) {
      // apply
      _.forOwn(result, (value, key) => handler(r, data, value, key));
    }
  });
};

// export
export { api, executeScript, updateDataAndExecuteScript, applyScript };
