import { Box, Heading, Text } from '@chakra-ui/react';
import { arrayOf, bool, InferProps, node, oneOfType } from 'prop-types';
import React, { Children, FC, ReactNode } from 'react';

const addSpacing = (children: ReactNode) =>
  Children.map(children, (child, idx) => (idx > 0 ? <> {child}</> : child));

const FwParagraph: FC<Props> = ({
  align,
  children,
  fluid,
  heading,
  small,
  truncated,
}) => {
  return heading ? (
    // heading is a Box as per Chakra UI docs
    <Heading
      fontSize={small ? 'sm' : 'md'}
      noOfLines={truncated ? 1 : undefined}
      textAlign={align}
      w={fluid ? '100%' : undefined}
    >
      {addSpacing(children)}
    </Heading>
  ) : (
    <Box
      as={Text}
      align={align}
      isTruncated={truncated}
      fontSize={small ? 'sm' : 'md'}
      lineHeight={small ? '1.1rem' : undefined}
      w={fluid ? '100%' : undefined}
    >
      {addSpacing(children)}
    </Box>
  );
};

const propTypes = {
  content: oneOfType([node, arrayOf(node), arrayOf(arrayOf(node))]),
  fluid: bool,
  heading: bool,
  small: bool,
  truncated: bool,
};

export interface Props extends InferProps<typeof propTypes> {
  align?: 'left' | 'center' | 'right' | 'justify';
}

FwParagraph.propTypes = propTypes;

export default FwParagraph;
