import { bool, func, InferProps, string } from 'prop-types';

const contentPT = {
  text: string,
  tooltip: string,
};

type ContentPT = InferProps<typeof contentPT>;

const interactivePT = {
  active: bool,
  onClick: func,
};

type InteractivePT = InferProps<typeof interactivePT>;

const sizePT = {
  chubby: bool,
  // compact: bool,
  small: bool,
};

type SizePT = InferProps<typeof sizePT>;

const cssStylePT = {
  backgroundColor: string,
  borderColor: string,
  color: string,
};

type CssStylePT = InferProps<typeof cssStylePT>;

const stylePT = {
  ...cssStylePT,
  leftIcon: string,
  leftIconColor: string,
  rightIcon: string,
  rightIconColor: string,
};

type StylePT = InferProps<typeof stylePT>;

export {
  contentPT,
  ContentPT,
  interactivePT,
  InteractivePT,
  sizePT,
  SizePT,
  cssStylePT,
  CssStylePT,
  stylePT,
  StylePT,
};
