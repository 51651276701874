import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';

import authenticationApi from 'api/authentication/authenticationApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, Identity, identity1Rules } from 'core/model';

const User: FC<Props> = ({ t, identity, handleSetUsername }) => {
  // get entityDoc for update user and password
  const castI: Identity = Object.assign(new Identity(), identity);

  // get entityDoc for update user and password
  const inputs0 = getAdminInputs(castI, null, t, '0');
  const entityDoc0 = getEntityDoc(identity, inputs0);

  // get entityDoc for update role
  const inputs1 = getAdminInputs(castI, null, t, '1');
  const entityDoc1 = getEntityDoc(identity, inputs1, identity1Rules);

  return (
    <>
      {identity && (
        <EntityInfo
          api={authenticationApi}
          entityDoc={entityDoc0}
          keyName={'identityID'}
          entityKey={identity.username}
          type={'identity'}
          title={'Identity'}
          onSetKey={handleSetUsername}
        />
      )}
      {identity.username && (
        <EntityInfo
          autosave
          api={authenticationApi}
          entityDoc={entityDoc1}
          keyName={'identityID'}
          entityKey={identity.username}
          type={'role'}
          title={'Role'}
        />
      )}
    </>
  );
};

const propTypes = {
  t: func.isRequired,
  identity: any.isRequired,
  handleSetUsername: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

User.propTypes = propTypes;

export default withTranslation()(User);
