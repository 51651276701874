import _ from 'lodash';
import { arrayOf, object } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Icon, Heading as Header } from '@chakra-ui/react';
// import styled from 'styled-components';

import {
  FwLabel,
  FwParagraph,
  FwSegment,
  FwTooltip,
  FwList,
  FwItem,
  FwPanel,
} from 'components/base';
import { useTitle } from 'core/hooks/useTitle';
import utils from 'core/utils/utils';

import { fieldKeysToDataValues } from './helpers';
// import PageSegment from '../../../../segments/PageSegment';
// import ThemedLabel from '../../../../theme/ThemedLabel';
// import ThemedPopup from '../../../../theme/ThemedPopup';
// import { ThemedHeader } from '../../../../theme/ThemedTypography';

// const ListLabel = styled(ThemedLabel)`
//   &&& {
//     display: block;
//     margin: 0 0 5px 0;
//   }
// `;

const TemplateHeader = ({
  // props
  doc,
  docData,
  template,
  viewingUsers,
}: any) => {
  const { t } = useTranslation();

  const { headerText, tabText } = template.additionalData || {};
  const fullData = utils.getFullData(doc, docData);

  const [headerValues] = useState(fieldKeysToDataValues(fullData, headerText));
  const [titleValue] = useState(
    _.join(fieldKeysToDataValues(fullData, tabText), ' - ')
  );

  useTitle(titleValue);

  // todo check design and colors
  return (
    <FwSegment
      basic
      center
      backgroundColor={template.backgroundColor}
      color={template.color}
    >
      <>
        {/*header as={ThemedHeader} textAlign="center"*/}
        <FwParagraph heading>{t(`custom|${template.name}`)}</FwParagraph>
        {(headerValues.length > 0 || viewingUsers.length > 0) && (
          <FwPanel center>
            <>
              {headerValues.length > 0 &&
                _.map(headerValues, (value, idx) => (
                  <FwLabel key={idx}>{value}</FwLabel>
                ))}
              {viewingUsers.length > 0 && (
                <FwLabel
                  error
                  leftIcon="RiEyeFill"
                  tooltip={
                    <>
                      <FwParagraph heading small>
                        {t('These users are viewing this document')}
                      </FwParagraph>
                      <FwList>
                        {viewingUsers.map(({ connectionId, username }) => (
                          <FwItem key={connectionId}>{username}</FwItem>
                        ))}
                      </FwList>
                    </>
                  }
                >
                  {`${viewingUsers.length}`}
                </FwLabel>
              )}
            </>
          </FwPanel>
        )}
      </>
      {/*header*/}
    </FwSegment>
  );
};

TemplateHeader.defaultProps = {
  viewingUsers: [],
};

// templateHeader.propTypes = {
//   docData: object.isRequired,
//   template: object.isRequired,
//   viewingUsers: arrayOf(object),
// };

export default TemplateHeader;
