import { arrayOf, bool, InferProps, object, string } from 'prop-types';

import { Props as InputProps } from '../../../components/base/elements/input/FwInput';
import { ctor } from '../helper';

const fwFieldPT = {
  collectionValue: arrayOf(object),
  label: string,
  noLabel: bool,
  tooltip: string,
};

type FwFieldPT = InferProps<typeof fwFieldPT> & InputProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwFieldProps extends FwFieldPT {}
class FwFieldProps implements FwFieldPT {
  constructor(props?: FwFieldPT) {
    ctor(this, props);
  }
}

export { fwFieldPT, FwFieldProps };
