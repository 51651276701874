import _ from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';

import documentApi from 'api/document/documentApi';
import DocumentEditionHub from 'api/document/documentEditionHub';
import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import {
  FwSpinner,
  FwToast,
  useFwArea,
  useFwModule,
  useFwTemplates,
} from 'components/base';
import { Document } from 'core/model';
import { FORM_LAYOUT_TYPE } from 'core/utils/constant';
import useMountedComponentRef from 'core/utils/useMountedComponentRef';

import Doc from './Doc';

const DocContainer = ({ match }: any) => {
  const { id, type, templateId } = match.params;

  const { area } = useFwArea();
  const { module } = useFwModule();
  const { templates } = useFwTemplates();

  const linkTemplateIDRef = useRef(
    (_.find(templates, { type: FORM_LAYOUT_TYPE.link }) || {}).templateId
  );
  const idRef = useRef(id);
  const mountedRef = useMountedComponentRef();
  const [document, setDocument] = useState<Document>();
  const [viewingUsers, setViewingUsers] = useState([]);
  const [autosave, setAutosave] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [createArgs] = useState(id ? [] : [templateId, undefined]);
  const [fetchArgs, setFetchArgs] = useState(
    id
      ? [id, type, undefined, area && area.key ? area.key : undefined, false] // missingTemps = false
      : []
  );
  const [hubArgs, setHubArgs] = useState({ channel: id });

  // create new document
  const { response: create, pending: pendingCreate } = useApi(
    !id && templateId ? documentApi.post : undefined,
    createArgs
  );

  // fetch from api
  const { fetched: fetch, pending: pendingFetch } = useApi(
    id || templateId ? documentApi.getByID : undefined,
    fetchArgs
  );

  // define hub handlers
  const addUserHandler = useCallback(
    (connectionId, username) => {
      if (mountedRef.current) {
        if (!viewingUsers || viewingUsers.length < 1) {
          FwToast.info('A user is viewing this document');
        }

        viewingUsers.push({ connectionId, username });
        setViewingUsers(_.uniqBy(viewingUsers, (vu) => vu.connectionId));
      }
    },
    [viewingUsers]
  );

  const acknowledgedHandler = useCallback(
    (channel, connectionId, username) => {
      if (idRef.current === channel && mountedRef.current) {
        viewingUsers.push({ connectionId, username });
        setViewingUsers(_.uniqBy(viewingUsers, (vu) => vu.connectionId));
      }
    },
    [viewingUsers]
  );

  const updatedHandler = useCallback(() => {
    if (mountedRef.current) {
      setShouldReload(true);
    }
  }, []);

  const removeConnectionHandler = useCallback(
    (connectionId) => {
      if (mountedRef.current) {
        _.remove(viewingUsers, { connectionId });
        setViewingUsers([...viewingUsers]);
      }
    },
    [viewingUsers]
  );

  // connect to hub
  useHub(id ? DocumentEditionHub : undefined, hubArgs, {
    addUserHandler,
    acknowledgedHandler,
    updatedHandler,
    removeConnectionHandler,
  });

  const reFetchDoc = useCallback(
    (docId) => {
      // re-init value to trigger re-render
      setDocument(undefined);
      setShouldReload(false);

      // fetch doc
      setFetchArgs([
        docId,
        type,
        undefined,
        area && area.key ? area.key : undefined,
        false,
      ]);

      // connect to hub
      setViewingUsers([]);
      setHubArgs({ channel: docId });
    },
    [area, type]
  );

  // api callbacks
  useEffect(() => {
    if (!pendingCreate && create) {
      const { data } = create;
      setFetchArgs([
        data.id,
        type,
        undefined,
        area && area.key ? area.key : undefined,
        false,
      ]);
    }
  }, [area, type, pendingCreate, create]);

  useEffect(() => {
    if (!pendingFetch && fetch) {
      const { document } = fetch;

      if (document) {
        const template = _.find(templates, {
          templateId: document.template && document.template.templateId,
        });

        if (template) {
          document.template = template;

          const shouldAutosave = template.additionalData['autosave'];

          if (shouldAutosave) {
            setAutosave(shouldAutosave);
          }
        }

        setDocument(document);
      }
    }
  }, [pendingFetch, fetch, templates]);

  // onRouteChange
  useEffect(() => {
    if (id && id !== idRef.current) {
      // update ref value
      idRef.current = id;

      reFetchDoc(id);
    }
  }, [id, reFetchDoc]);

  return !document ? (
    // todo improve -> error message instead of infinite loading?
    <FwSpinner />
  ) : (
    <Doc
      area={area}
      module={module}
      autosave={autosave}
      document={document}
      linkTemplateID={linkTemplateIDRef.current}
      shouldReload={!autosave && shouldReload}
      reFetchDoc={reFetchDoc}
      viewingUsers={viewingUsers}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  // render only if param id is changed
  const { id: prevId } = prevProps.match.params;
  const { id: nextId } = nextProps.match.params;

  return (!prevId && !nextId) || prevId === nextId;
};

export default React.memo(DocContainer, areEqual);
