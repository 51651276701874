import { PopoverContent, PopoverContentProps } from '@chakra-ui/react';
import React, { FC } from 'react';

import { defaultSpacing } from 'config/theme/constants';

const PopContent: FC<PopoverContentProps> = ({ children, ...props }) => {
  const style = {
    cursor: 'default',
    minW: 'max-content',
    w: 'full',
    boxShadow: 'none !important',
    maxHeight: '21rem',
    p: defaultSpacing,
    // {
    //   base: '6rem',
    //   sm: '10rem',
    //   lg: '16rem',
    //   xl: '21rem',
    // },
  };

  return (
    <PopoverContent overflowX={'auto'} {...style} {...props}>
      {children}
    </PopoverContent>
  );
};

export default PopContent;
