import { arrayOf, InferProps, string, func } from 'prop-types';
import React, { FC } from 'react';

import { FwArticle, FwSegment, useFwTheme } from 'components/base';

const FwCard: FC<Props> = ({ content, borderColor, onClick }) => {
  const { accent } = useFwTheme();

  return (
    <FwSegment borderColor={borderColor || accent} onClick={onClick}>
      <FwArticle small content={content} />
    </FwSegment>
  );
};

const propTypes = {
  borderColor: string,
  content: arrayOf(arrayOf(string)),
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

FwCard.propTypes = propTypes;

export default FwCard;
