import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { Dict } from '@chakra-ui/utils';
import chroma from 'chroma-js';
import _ from 'lodash';

import { fwAccent } from './constants';

// use chroma-js to generate palette from a color (defaults to FasterWeb gray palette)
// resulting palette will follow chakra structure
// it will contain 19 colors from 50 to 900 (19*50-1*50=900)
const buildPalette = (color?: string) => {
  let palette: string[];
  const validColor = color && chroma.valid(color);

  if (!validColor) {
    // default to black and white palette
    palette = chroma
      .scale(['white', '#f0f0f1', '#202127', 'black'])
      // specified color must be at 800 (default Chakra value for dark theme background)
      .domain([0, 150, 800, 900])
      .colors(19);
  } else {
    // build a color palette which contains the color it is built from
    const wContrast = chroma.contrast('white', color) + 2;
    const colorIsLight = wContrast < 4.5;

    palette = chroma
      .scale(['white', color, 'black'])
      // specified color must be at 200 (for dark theme) or 500 (for light theme)
      .domain([0, colorIsLight ? 200 : 500, 900])
      .mode('hsl')
      .colors(19);
  }

  // convert to Chakra UI palette format
  const chakraPalette = _.reduce(
    palette,
    (current, item) => {
      current[palette.indexOf(item) * 50] = item;
      return current;
    },
    {}
  );

  return chakraPalette;
};

// define themed object structure and default palette shades for theme
const getThemedObject = (light: string, dark: string) => ({
  _light: light,
  _dark: dark,
});
const shades = getThemedObject('500', '200');

// convert one color into light and dark values
const getThemedColor = (color: string) => {
  let themedColor: Record<string, string>;

  if (color) {
    const palette = buildPalette(color);

    themedColor = getThemedObject(
      palette[shades._light],
      palette[shades._dark]
    );
  }

  return themedColor || {};
};

// color validation
const validateColor = (color: string) => {
  let validColor = undefined;
  const lowCaseColor = _.toLower(color);

  // todo wip#585 consider having 2 funcs: 1 for color / 1 for bg
  if (
    color &&
    // see getValidColor function for possible refactor
    (CSS.supports('color', color) || CSS.supports('background', color))
  ) {
    validColor = lowCaseColor;
  }

  return validColor;
};

const getFallbackColor = (color: string) => {
  const validColor = _.find(_.map(_.split(color, ','), _.trim), (c) =>
    chroma.valid(c)
  );

  return validColor
    ? validColor === color
      ? chroma(validColor).darken().hex()
      : validColor
    : undefined;
};

const getValidColor = (
  color: string,
  colorModeContext: Dict | StyleFunctionProps
): string => {
  let validColor = undefined;
  const colorArray = _.split(color, '|');

  if (colorArray?.length === 2) {
    // color has 2 values (depending on theme)
    const themedObj = getThemedObject(
      validateColor(colorArray[0]),
      validateColor(colorArray[1])
    );

    // select color based on current mode (light/dark)
    validColor = mode(themedObj._light, themedObj._dark)(colorModeContext);
  } else if (colorArray?.length === 1) {
    // validate unique color value
    validColor = validateColor(colorArray[0]);
  }

  // see validateColor function for possible refactor
  if (
    !CSS.supports('color', validColor) &&
    CSS.supports('background-image', validColor)
  ) {
    const fallback = getFallbackColor(validColor);

    if (fallback) {
      validColor = `${fallback} ${validColor}`;
    }
  }

  return validColor;
};

// define theme-dependent main custom color variable
const colors = {
  [fwAccent]: getThemedObject(
    `${fwAccent}.${shades._light}`,
    `${fwAccent}.${shades._dark}`
  ),
};

// override Chakra UI gray palette and build palette for accent color
const getPalettes = (accent: string) => ({
  [fwAccent]: buildPalette(accent),
  gray: buildPalette(),
});

export { colors, getFallbackColor, getPalettes, getThemedColor, getValidColor };
