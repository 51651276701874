import _ from 'lodash';

import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, BatchProcess, Document, Filter } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { getFileNameBuildKeysQuery } from 'core/utils/tableData/getQueryString';

const processFilters = (filters: Filter[]) => {
  const apiFilters = [];

  _.forEach(filters, (filter) => {
    const apiFilter = {
      key: filter.key,
      operator: filter.operator,
      value: filter.value,
      type: filter.type,
    };

    const { value } = apiFilter;

    if (_.isArray(value) && value) {
      apiFilter.value = value.join('|');
    }

    if (
      _.includes([FIELD_TYPE.checkbox], typeMapper(filter.type)) &&
      value === undefined
    ) {
      // value by default
      apiFilter.value = `${false}`;
    }

    apiFilters.push(apiFilter);
  });

  return apiFilters;
};

const documentApi = {
  getByID: (
    key: string,
    type: string,
    id: string,
    area: string,
    withTemplate: boolean,
    params: BaseApiParams
  ) => {
    const urlArray = ['document'];

    if (withTemplate) {
      urlArray.push('withTemplate');
    } else if (type) {
      urlArray.push(area);
      urlArray.push(type);
    }

    urlArray.push(key);

    if (id) {
      urlArray.push(id);
    }

    return BaseApi.get<{ document: Document }>(urlArray.join('/'), params);
  },
  getIDs: (filters: Filter[], params?: BaseApiParams) => {
    // process filters before send request
    const apiFilters = processFilters(filters);
    return BaseApi.post<
      { filters: Filter[] },
      { ids: string[]; document: Document }
    >(`document/id/filter`, { filters: apiFilters }, params);
  },
  getByIDs: (ids: string[], params: BaseApiParams) =>
    BaseApi.post(`document/id`, { ids }, params),
  process: (batchProcess: BatchProcess, params?: BaseApiParams) => {
    // process filters before send request
    batchProcess.filters = processFilters(batchProcess.filters);
    return BaseApi.post<{ batchProcess: BatchProcess }, { ids: string[] }>(
      'document/process',
      { batchProcess },
      params
    );
  },
  post: (templateID: string, doc: Document, params?: BaseApiParams) =>
    BaseApi.post(`document`, { document: doc, templateID }, params),
  put: (doc: Document, params?: BaseApiParams) =>
    BaseApi.put(`document`, { document: doc }, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<Document, string>,
    params: BaseApiParams
  ) => BaseApi.put(`document/AS`, { autoSaveInfo }, params),
  print: (documents: Document[], params: BaseApiParams) =>
    BaseApi.post(`document/print`, { documents }, params),
  getDownloadTemplates: (params?: BaseApiParams) =>
    BaseApi.get<{ downloadTemplateNames: string[] }>(
      `document/downloadTemplates`,
      params
    ),
  downloadAs: (
    docID: string,
    templateName: string,
    fileType: string,
    downloadFileName: string[],
    tableDataQuery: TableDataQuery[],
    params?: BaseApiParams
  ) => {
    // build fileName prefix then append it to query
    const fileNameQuery = getFileNameBuildKeysQuery(downloadFileName);
    const queryString = `${templateName}/${fileType}/${docID}?${fileNameQuery}`;

    return _.some(tableDataQuery)
      ? BaseApi.post<{ tableDatas: TableDataQuery[] }, { fileUrl: string }>(
          `document/download/export/${queryString}`,
          { tableDatas: tableDataQuery },
          params
        )
      : BaseApi.get<{ fileUrl: string }>(
          `document/download/${queryString}`,
          params
        );
  },
  downloadManyAs: (
    ids: string[],
    templateName: string,
    fileType: string,
    downloadFileName: string[],
    params?: BaseApiParams
  ) => {
    // build fileName prefix then append it to query
    const fileNameQuery = getFileNameBuildKeysQuery(downloadFileName);

    return BaseApi.post<{ ids: string[] }, { fileUrl: string }>(
      `document/download/${templateName}/${fileType}?${fileNameQuery}`,
      { ids },
      params
    );
  },
};

export default documentApi;

export type TableDataQuery = {
  tableKey: string;
  ids: string[];
};
