import { Td, chakra } from '@chakra-ui/react';
import _ from 'lodash';
import { any, number, func, InferProps } from 'prop-types';
import React, { FC, Fragment } from 'react';

import { FwTooltip, FwParagraph, useFwTheme } from 'components/base';
import { dateMax, dateMin, initDateTime } from 'core/utils/date';

import { dateToTime } from '../FwMask.Timeline.helpers';
import EventLabel from './EventLabel';

const TimelineProgress: FC<Props> = ({
  selectedDate,
  view,
  data,
  slots,
  handleOpen,
  processes,
  handleProcessActionClick,
}) => {
  const { start: startView, end: endView } = view;
  const {
    boxShadow,
    _disabled: { boxShadow: halfBoxShadow },
  } = useFwTheme();

  const getLabelProps = (event) => {
    const { start: startEvent, end: endEvent, text, color } = event;

    // show event progress within this timerange's view limit: (startView, endView + 30 mins)
    // pass all the time values to override all the time's parts
    const currentDateStartView = initDateTime(selectedDate, startView, 0, 0, 0);
    const currentDateEndView = initDateTime(selectedDate, endView, 30, 0, 0);

    const startH =
      dateMax(startEvent, currentDateStartView).getHours() - startView;
    const endH = dateMin(endEvent, currentDateEndView).getHours() - startView;

    const startMinutes = dateMax(startEvent, currentDateStartView).getMinutes();
    const startDecimalSlot = (startMinutes - 30) / 30.0;

    const endMinutes = dateMin(endEvent, currentDateEndView).getMinutes();
    const endDecimalSlot = (endMinutes - 30) / 30.0;

    const /*offset = 0,*/
      slot = startH * 2 + 1 + (1 + startDecimalSlot),
      slots = endH * 2 + 1 + (1 + endDecimalSlot) - slot,
      left = startDecimalSlot >= 0 ? startDecimalSlot : 1 + startDecimalSlot,
      width = 0,
      compact = false,
      empty = text ? undefined : true,
      backgroundColor = color;
    //const { start, length, end } = event;

    //console.log(slot, slots, startH, endH, startDecimalSlot, endDecimalSlot);
    //if (progressRef.current) {
    //  const totalWidth = progressRef.current.offsetWidth;

    //  const timeStartVal = dateToSecond(start);
    //  const timeEndVal = dateToSecond(end);
    //  const timeLengthVal = dateToSecond(length);

    //  const widthTime = timelineEnd - timelineStart;

    //  if (totalWidth) {
    //    //offset = timeStartVal - timelineStart;
    //    //slot = offset / 3600;
    //    //left = (offset / widthTime) * 100;
    //    width =
    //      ((timeLengthVal || timeEndVal - timeStartVal) / widthTime) * 100;
    //    compact = (width / 100) * totalWidth < 35 ? true : false;
    //  }
    //}

    return {
      slot,
      slots,
      left,
      width,
      compact,
      empty,
      backgroundColor,
    };
  };

  const labels = _.sortBy(data.labels, [(lbl) => lbl.start, (lbl) => -lbl.end]);

  const otherSlots = [];

  const labelRenders = _.map(labels, (lbl, idx) => {
    const { key, start, end, text } = lbl;
    const lblProps = getLabelProps(lbl);

    return [
      lblProps.slot,
      <FwTooltip
        key={idx}
        placement="bottom"
        text={
          <FwParagraph small>
            {`${dateToTime(start)} - ${dateToTime(end)}`}
            {_.map(text.split('\n'), (line, index) => (
              <Fragment key={index}>
                <br />
                {line}
              </Fragment>
            ))}
          </FwParagraph>
        }
      >
        <EventLabel
          {...lblProps}
          processes={processes}
          eventKey={key}
          content={text}
          onDoubleClick={(e) => {
            e.stopPropagation();
            handleOpen(key);
          }}
          handleOpen={handleOpen}
          handleProcessActionClick={handleProcessActionClick}
        />
      </FwTooltip>,
    ];
  });

  for (let k = 1; k < slots; k++) {
    const myArray = _.map(
      _.filter(labelRenders, (lr) => lr[0] >= k && lr[0] < k + 1),
      (lc) => lc[1]
    );
    const halfHour = k % 2 === 0;

    otherSlots.push(
      <Td
        key={k}
        boxShadow={halfHour ? halfBoxShadow : boxShadow}
        w="40px"
        maxW="40px"
        minW="40px"
      >
        {!_.isEmpty(myArray) && (
          <chakra.div pos="relative" top={0} m={0} h="100%" w="100%">
            {myArray}
          </chakra.div>
        )}
      </Td>
    );
  }

  return (
    <>
      <Td w="40px" maxW="40px" minW="40px" />
      {otherSlots}
    </>
  );
};

const propTypes = {
  view: any,
  data: any,
  slots: number,
  handleOpen: func,
  processes: any,
  handleProcessActionClick: func,
};

export interface Props extends InferProps<typeof propTypes> {
  selectedDate: Date;
}

TimelineProgress.propTypes = propTypes;

export default TimelineProgress;
