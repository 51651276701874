import { arrayOf, InferProps, instanceOf, string } from 'prop-types';

import { Execution, Row } from '../dtoIndex';
import { ctor } from '../helper';

const fwProcessModalPT = {
  objectData: instanceOf(Row),
  processID: string,
  processSteps: arrayOf(instanceOf(Execution)),
};

type FwProcessModalPT = InferProps<typeof fwProcessModalPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwProcessModalProps extends FwProcessModalPT {}
class FwProcessModalProps implements FwProcessModalPT {
  constructor(props?: FwProcessModalPT) {
    ctor(this, props);
  }
}

export { fwProcessModalPT, FwProcessModalProps };
