import _ from 'lodash';

import {
  computeDateRange,
  Views,
} from 'components/base/containers/mask/agenda/FwMask.Agenda.helpers';
import { CONTENT_TYPE } from 'core/utils/constant';
import {
  dateFormats,
  getDateOrDefault,
  jsDateFromString,
} from 'core/utils/date';

const { agenda, timeline } = CONTENT_TYPE;

// update search by input criterion
const setCriterion = (criteria: any, { key, value }: any) => {
  criteria.searchByInput[key] = value === '' ? undefined : value;
};

// initialize criteria
const getDateCriteria = (
  { start, end }: { start?: string[]; end?: string[] },
  date: Date,
  view?: keyof Views
) => {
  const { rangeString } = computeDateRange(date, view);
  const [startVal, endVal] = rangeString.split('|');

  const startField = start?.[0];
  const endField = end?.[0];

  return !startField || !endField || startField === endField
    ? [{ key: startField || endField, value: `${startVal}|${endVal}` }]
    : [
        { key: startField, value: `|${endVal}` },
        { key: endField, value: `${startVal}|` },
      ];
};

const initializeMaskCriteria = (maskData, criteria) => {
  const {
    view: { date, type },
    document,
  } = maskData;

  const dateCriteria = getDateCriteria(
    document,
    getDateOrDefault(jsDateFromString(date, dateFormats.isoDate)),
    type
  );

  _.forEach(dateCriteria, (dateCriterion) =>
    setCriterion(criteria, dateCriterion)
  );
};

const initializeTableCriteria = (
  table: any,
  search: any,
  sort: any,
  filters: any,
  mask: any,
  maskData: any
) => {
  const initCrit: any = { searchByInput: {} };

  // add criteria for filters
  if (filters && filters.length > 0) {
    _.forOwn(filters, (filter) => {
      _.forOwn(filter, (value, key) => {
        setCriterion(initCrit, { key, value });
      });
    });
  }

  //// add criteria for user filters
  //if (userFilters && userFilters.length > 0) {
  //  _.forEach(userFilters, userFilter => {
  //    setCriterion(initCrit, userFilter);
  //  });
  //}

  // read sort from args
  const ascendingSort =
    sort && sort.direction
      ? sort.direction === 'ascending'
      : table.ascendingSort;
  const columnSort = sort && sort.key ? sort.key : table.sortByColumnKey;

  // read search from args
  initCrit.searchAllByValue = search;

  // add criteria for sort by default
  initCrit.orderByField = ascendingSort ? columnSort : undefined;
  initCrit.orderByFieldDesc = ascendingSort ? undefined : columnSort;

  // add criteria for table mask
  if (mask) {
    // if mask is agenda
    if (_.includes([agenda, timeline], mask)) {
      initializeMaskCriteria(maskData, initCrit);
    }
  }

  return initCrit;
};

export { getDateCriteria, initializeTableCriteria, setCriterion };
