import { AppPreference } from 'core/model';
import { FIELD_TYPE, LOCALES } from 'core/utils/constant';

import { AdminStructure } from '..';

const appPreferenceAS: AdminStructure<AppPreference> = [
  {
    key: 'language',
    name: 'Language',
    type: FIELD_TYPE.select,
    dropdown: {
      dropdownID: 1,
      options: [
        {
          key: LOCALES.en as string,
          value: LOCALES.en as string,
          text: 'English',
        },
        {
          key: LOCALES.fr as string,
          value: LOCALES.fr as string,
          text: 'Français',
        },
      ],
    },
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  //{
  //  position: 2,
  //  name: 'Default module',
  //  key: 'defaultModule',
  //  type: FIELD_TYPE.text,
  //},
  {
    key: 'smallMenu',
    name: 'Collapse menu',
    type: FIELD_TYPE.checkbox,
    row: 2,
    column: 1,
    additionalData: { noLabel: true },
    subInputs: [],
    visible: true,
  },
];

export { appPreferenceAS };
