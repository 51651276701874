import L from 'leaflet';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { useMapEvent } from 'react-leaflet';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure, MapView } from '../FwMask.structures';

// todo wip#125 refactor
const DblClickComponent = () => {
  const map = useMapEvent('dblclick', (e: any) => {
    const zoom = map.getZoom();
    map.setView(e.latlng, zoom + 2);
  });

  return null;
};

// todo wip#125 refactor
const RightClickComponent = () => {
  const map = useMapEvent('contextmenu', () => {
    const zoom = map.getZoom();
    map.setZoom(zoom - 2);
  });

  return null;
};

//// compute both start and end of event
//const getCoordinatesXY = (data, { defaultX, defaultY }) => {
//  const coordinatesXY = {
//    coordinateX: data.CoordinateX,
//    coordinateY: data.CoordinateY,
//  };

//  if (!coordinatesXY.coordinateX && coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//  } else if (coordinatesXY.coordinateX && !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateY = defaultY;
//  }

//  // data is missing -> fallback to default cooordinate of map
//  if (!coordinatesXY.coordinateX || !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//    coordinatesXY.coordinateX = defaultY;
//  }

//  return coordinatesXY;
//};

const getLabelSize = (label: string) => {
  let labelFontSize = 0.8;

  if (label?.length <= 3) {
    labelFontSize = 0.8;
  } else if (label?.length <= 4) {
    labelFontSize = 0.6;
  } else {
    labelFontSize = 0.5;
  }

  return labelFontSize;
};

// todo wip#125 refactor
// convert mask row to map marker
const maskRowToMarker = (
  maskStructure: MaskStructure,
  maskRow: any,
  t: TFunction
) => {
  const {
    document: { longitude, latitude, markerText },
  } = maskStructure;
  const { key, data: rowData, color } = maskRow;

  // get coordinates X and Y
  const coordinateX = rowData[longitude];
  const coordinateY = rowData[latitude];

  // get marker text
  const label = {
    text:
      (rowData[markerText]?.length > 4
        ? rowData[markerText].substring(0, 4) + '<br>...'
        : rowData[markerText]) || '',
    additionalStyle:
      rowData[markerText]?.length > 4
        ? `font-size: ${getLabelSize(
            rowData[markerText]
          )}rem;overflow: hidden; line-height: 0.8;`
        : `font-size: ${getLabelSize(rowData[markerText])}rem;`,
  };

  // define tooltip
  const tooltip = getMaskRowText(maskStructure, rowData, t);

  return coordinateX && coordinateY
    ? [key, coordinateX, coordinateY, tooltip, color, label]
    : undefined;
};

// retrieve geoJson from maskRows and maskStructure
const maskRowToGeoJson = (maskStructure: MaskStructure, maskRow: any) => {
  const {
    document: { geo },
  } = maskStructure;

  const { data: rowData } = maskRow;

  // retrieve all features from GeoJSON (if it's a FeatureCollection, it will be an array of features)
  const features = _.flatMap(geo, (geoItem) => {
    const geoJson = rowData[geoItem];

    let data = [];

    if (geoJson) {
      try {
        const parsed = JSON.parse(geoJson);
        if (parsed.type === 'FeatureCollection') {
          data = parsed.features;
        } else if (parsed.type === 'Feature') {
          data = [parsed];
        }
      } catch (e) {
        //console.error(e);
      }
    }
    return data;
  });
  return !features || features.length === 0 ? [] : features;
};

const getGeoJsonFeatureCollection = (features) => {
  const finalGeoJson = {
    type: 'FeatureCollection',
    features: [],
  };

  finalGeoJson.features = _.flatMap(features, (feature) => feature);

  return finalGeoJson;
};

// todo wip#125 refactor
const validateViewport = (view: MapView) => {
  let isValid = false;

  if (view) {
    const { center, zoom } = view;

    if (
      center &&
      Array.isArray(center) &&
      center.length === 2 &&
      Number.isFinite(center[0]) &&
      Number.isFinite(center[1]) &&
      zoom &&
      Number.isFinite(zoom)
    ) {
      isValid = true;
    }
  }

  return isValid;
};

// todo wip#125 refactor
const markerHtmlStyles = (color: string) => `
  background-color: ${color};
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -0.7rem;
  top: -1.72rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 3px solid #FFFFFF;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;  
`;

const textHtmlStyles = (additionalStyle: string) =>
  `
  position: relative;
  font-weight: semi-bold;
  color: #FFFFFF;
  transform: rotate(-45deg);
  ${additionalStyle}
`;

const AltZoomComponent = () => {
  const map = useMapEvent('mousemove', (e: any) => {
    if (!e.originalEvent.altKey) {
      map.scrollWheelZoom.disable();
    } else {
      e.originalEvent.preventDefault();
      map.scrollWheelZoom.enable();
    }
  });

  return null;
};

// todo wip#125 refactor
const getMarkerIcon = (color: string) =>
  L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 0],
    //labelAnchor: [0, 0],
    tooltipAnchor: [0, -9.8],
    popupAnchor: [0, -19.6],
    html: `<span style="${markerHtmlStyles(color)}"></span>`,
  });

const getLabelledMarkerIcon = (color: string, label: any) =>
  L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 0],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, -9.8],
    popupAnchor: [0, -19.6],
    html: `<div style="${markerHtmlStyles(
      color
    )}"><span style="${textHtmlStyles(label.additionalStyle)}">${
      label.text
    }</span></div>`,
  });

const optionByProp = {
  ['stroke']: 'color',
  ['stroke-width']: 'width',
  ['stroke-opacity']: 'opacity',
  ['fill']: 'fillColor',
  ['fill-opacity']: 'fillOpacity',
};

const onEachFeature = (feature: any, layer: any) => {
  const {
    geometry: { type },
    properties,
  } = feature;

  if (type == 'Polygon' || type == 'LineString') {
    _.forEach(properties, function (value, key) {
      layer.options[optionByProp[key]] = value;
    });
  } else if (type == 'Point') {
    layer.options.icon = L.icon({
      iconUrl: 'Storage/Image/marker-icon-2x.png',
      shadowUrl: 'Storage/Image/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      shadowSize: [41, 41],
      shadowAnchor: [12, 41],
    });
  }
};

export {
  AltZoomComponent,
  DblClickComponent,
  getGeoJsonFeatureCollection,
  getLabelledMarkerIcon,
  getMarkerIcon,
  maskRowToGeoJson,
  maskRowToMarker,
  onEachFeature,
  RightClickComponent,
  validateViewport,
};
