import _ from 'lodash';

import { ACTION, CONTENT_TYPE } from 'core/utils/constant';

import { applyScript } from '../../form/components/template/helpers/executeScript';

import { updateDocDataOnLoad } from '.';

const { fill, onChange, onLoad } = ACTION;
const { field } = CONTENT_TYPE;

const ruleHandler = (rule: any, docData: any, value: any, key: any) => {
  docData[key] = value;
};

const getDocData = (doc, docData?, updatedKeys?, indexItemChange?) => {
  const { rules } = doc.template;
  const data = _.cloneDeep(docData || (doc.data ? JSON.parse(doc.data) : {}));

  const fillRules = updatedKeys
    ? rules.filter(
        (r) =>
          r.script.action === fill &&
          r.event.action === onChange &&
          r.script.type === field &&
          _.includes(updatedKeys, r.event.key)
      )
    : rules.filter(
        (r) =>
          r.script.action === fill &&
          r.event.action === onLoad &&
          r.script.type === field
      );

  applyScript(fillRules, {
    doc,
    data,
    handler: ruleHandler,
    indexItemChange,
  });

  if (!updatedKeys) {
    // onLoad docData then process input collection/reference data if has
    updateDocDataOnLoad(doc, data);
  }

  return data;
};

export default getDocData;
