import _ from 'lodash';

import { FwCategoryProps, Option } from 'core/model';
import { isNoRenderItem } from 'core/utils/logic';

import { mapOptionToItem } from './mapOptionToItem';

const mapOptionToCategory = (option: Option) => {
  return option && !isNoRenderItem(option)
    ? new FwCategoryProps({
        categoryKey: option.key,
        name: option.text,
        items: _.map(option.items, (item) => mapOptionToItem(item)),
      })
    : null;
};

export { mapOptionToCategory };
