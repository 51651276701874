import _ from 'lodash';

import { Step } from 'core/model';

import documentRoutes from '../../document/documentRoutes';

const getStepIndex = (routeName: string) => {
  return _.indexOf(
    _.split(
      _.find(documentRoutes, (route) => route.name === routeName).path,
      '/'
    ),
    ':step'
  );
};

// retrieve the basePath using the structure of the routes in the documentRoutes file
export const getBasePath = (): string => {
  const currentLocationArray = _.filter(
    _.split(window.location.pathname, '/'),
    (item) => item !== ''
  );

  let stepIndex = 0;

  if (_.includes(currentLocationArray, 'new')) {
    stepIndex = getStepIndex('new-with-step');
  } else if (_.includes(currentLocationArray, 'public')) {
    if (_.includes(currentLocationArray, 'tracking')) {
      stepIndex = getStepIndex('public-tracking-with-step');
    } else {
      stepIndex = getStepIndex('public-with-step');
    }
  } else {
    stepIndex = getStepIndex('doc-with-step');
  }

  const basePath = _.join(_.slice(currentLocationArray, 0, stepIndex), '/');

  return basePath;
};

// add the step name at the end of the URL (purely visual, no actions)
export const updateURL = (basePath: string, name: string): void => {
  if (basePath !== null && name !== null) {
    window.history.replaceState(null, null, `${basePath}/${name}`);
  }
};

// retrieve the current step from the URL if it exists, or return the first step in the steps array if it isn't empty
export const getInitStepID = (stepID: string, stepsArray: Step[]): string => {
  let initStep: string = null;

  if (stepsArray && stepsArray.length > 0) {
    initStep =
      _.find(stepsArray, (s) => s.stepID === stepID)?.stepID ||
      stepsArray[0].stepID;
  }

  return initStep;
};
