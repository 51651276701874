import { HStack } from '@chakra-ui/react';
import { InferProps, arrayOf, string } from 'prop-types';
import React, { FC } from 'react';

import { FwField } from 'components/base';
import { FwFieldProps } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

const { text } = FIELD_TYPE;

const FwFilter: FC<FwFieldProps & Props> = ({
  filterData: { key, operator, value, searchType },
  // operatorOptions,
  onChange,
  ...props
}) => {
  return (
    <HStack>
      {/*todo #597 change type text to select*/}
      <FwField type={text} value={key} name={'key'} onChange={onChange} />
      {/*todo #597 filter operator options by input type*/}
      <FwField
        type={text}
        value={operator}
        name={'operator'}
        onChange={onChange}
      />
      <FwField
        // todo #597 invalid is pass in props so atm only this input is highlight when invalid
        {...props}
        noLabel
        type={searchType}
        value={value}
        name={'value'}
        onChange={onChange}
      />
    </HStack>
  );
};

const propTypes = {
  operator: string,
  operatorOptions: arrayOf(string),
};

export type Props = InferProps<typeof propTypes>;

FwFilter.propTypes = propTypes;

export default FwFilter;
