import { arrayOf, func, InferProps, instanceOf, string } from 'prop-types';

import { ctor } from '../helper';
import { sizePT, stylePT } from './common';
import { FwItemProps } from './FwItem.props';

const fwCategoryPT = {
  ...sizePT,
  ...stylePT,
  categoryKey: string,
  name: string,
  items: arrayOf(instanceOf(FwItemProps)),
  onChange: func,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwCategoryPT extends InferProps<typeof fwCategoryPT> {
  // onChange?: ;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwCategoryProps extends FwCategoryPT {}
class FwCategoryProps implements FwCategoryPT {
  constructor(props?: FwCategoryPT) {
    ctor(this, props);
  }
}

export { fwCategoryPT, FwCategoryProps };
