import _ from 'lodash';
import { any, bool, func, InferProps /*, Requireable*/ } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFwModule } from 'components/base/utility';
import utils from 'core/utils/utils';
//import { Module } from 'core/dto/interfaces/module'; // For type checking modules parameter

import FwIcon from '../../elements/icon/FwIcon';
import FwItem from '../../elements/item/FwItem';
import FwList from '../list/FwList'; // tODO: import the following from base instead?

/** responsability: treat the modules to render a module navigation interface */
const FwNav: FC<Props> = ({ modules, isExpanded, callback }) => {
  const { module } = useFwModule(); // tODO: could simply receive the active module from parent component if parent component uses this hook.
  const { t } = useTranslation();

  return (
    <FwList as={'nav'} fluid>
      {_.map(modules, ({ key, path, name, design }, index) => (
        <FwItem
          key={index}
          chubby
          active={module.key === key}
          center={isExpanded ? undefined : true}
          tooltip={isExpanded ? undefined : t(`common|${name}`)}
          onClick={() => {
            callback(path);
          }}
        >
          {design?.icon && (
            <FwIcon
              inline={isExpanded ? true : undefined}
              name={design.icon}
              color={design ? utils.getColor(design.iconColor) : undefined}
            />
          )}
          {isExpanded && t(`common|${name}`)}
        </FwItem>
      ))}
    </FwList>
  );
};

// tODO: type Module does not have path, name and design. Cannot constrain modules to Module[]... yet.
//
// const moduleList: Requireable<Module[]> = arrayOf<Module>((prop, index, componentName) => {
// // <Module> is a way to brand the validator. See: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28015#issuecomment-446309615
// let error: Error;
// if (prop[index].type.name !== "Module") {
//     error = new Error(`${componentName} module parameter requires an array of Module interfaces`);
// }
// return error;
// })

// nOTE: isExpanded and navcallback could be defined in FwNav but would require property drilling through their parent for the history and settings properties.
const propTypes = {
  modules: any, //moduleList.isRequired,
  isExpanded: bool,
  callback: func, //tODO: Further limit the type? Requireable<(typeof path) => module>
};

export type Props = InferProps<typeof propTypes>;

FwNav.propTypes = propTypes;

export default FwNav;
