import { chakra, Divider, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { InferProps, string } from 'prop-types';
import React, { ChangeEvent, FC, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { FwButton, FwImage, FwParagraph, useFwTheme } from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import { createJsonString } from 'core/utils/form/getValueChange';
import utils from 'core/utils/utils';

import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';

const FwFileInput: FC<Props & CommonInputProps> = ({
  clearable,
  defaultValue,
  disabled,
  name,
  placeholder,
  readOnly,
  saved,
  type,
  unchanged,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const { accent } = useFwTheme();

  const defaultText = value || defaultValue;
  const isUpdatable = onChange || !value;

  const isPhoto = type === FIELD_TYPE.photo;
  const isFile = type === FIELD_TYPE.file;

  const acceptFiles = isPhoto
    ? {
        'image/*': [],
      }
    : {};

  const [text, setText] = useState(utils.getOriginalFilename(defaultText));
  const [fileUrl, setFileUrl] = useState(utils.getPath(defaultText));
  const [originalFile, setOriginalFile] = useState<File>();
  const [data, setData] = useState('');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    dataString?: string,
    filename?: string
  ) => {
    if (onChange) {
      const val =
        filename && dataString ? createJsonString(filename, dataString) : '';
      onChange(
        e,
        utils.getNameValueFromEData(e, {
          name,
          value: val,
        })
      );
    }
  };

  const fileChangeCore = (e: ChangeEvent<HTMLInputElement>, file: File) => {
    const res = utils.fileValidated(file, isPhoto);

    if (res && res.result) {
      // set data as local (no url)
      setFileUrl('');
      setOriginalFile(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setData(reader.result.toString());

        if (isUpdatable) {
          setText(file.name);
          handleChange(e, reader.result?.toString(), file.name);
        }
      };
    }
  };

  const handleFileDrop = (e: DropEvent, files: File[]) => {
    const file = files?.[0];

    if (file) {
      fileChangeCore(e as ChangeEvent<HTMLInputElement>, file);
    }
  };

  const handleResetFile = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();

    if (isUpdatable) {
      // reset state
      setText('');
      setData('');
      setFileUrl('');

      // notify parent
      handleChange(e);
    }
  };

  const handleOpenInNewTab = () => {
    if (fileUrl || originalFile) {
      utils.openInNewTab(fileUrl || URL.createObjectURL(originalFile));
    }
  };

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    multiple: false,
    accept: acceptFiles,
    disabled: disabled || readOnly,
    noClick: true,
    noDrag: disabled || readOnly,
    onDrop: (acceptedFiles, _fileRejections, event) => {
      handleFileDrop(event, acceptedFiles);
    },
  });

  const src = data ? data : fileUrl ? fileUrl : undefined;

  const groupStyle = {
    sx: {
      '& .chakra-input__left-addon': {
        height: 'unset',
        cursor: !disabled && !readOnly ? 'pointer' : undefined,
      },
    },
  };

  return (
    <>
      <chakra.input {...getInputProps()} />
      <InputGroup {...groupStyle}>
        <FwButton
          small
          as={InputLeftAddon}
          disabled={disabled || readOnly}
          leftIcon="RiAddFill"
          onClick={open}
        >
          {t('common|Select')}
        </FwButton>
        <Base
          {...getRootProps({ className: 'dropzone' })}
          readOnly
          borderColor={isDragAccept ? accent : undefined}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          rightIcon={clearable !== false && text ? 'RiCloseFill' : undefined}
          saved={saved}
          unchanged={unchanged}
          value={text || ''}
          onRightIconClick={handleResetFile}
        />
      </InputGroup>

      {src && (
        <>
          <Divider m={'5px 0px'} />
          <FwParagraph align="center">
            {isPhoto && <FwImage src={src} onClick={handleOpenInNewTab} />}
            {isFile && (
              <FwButton
                small
                leftIcon={'RiDownloadLine'}
                onClick={handleOpenInNewTab}
              >
                {t('common|Download')}
              </FwButton>
            )}
          </FwParagraph>
        </>
      )}
    </>
  );
};

const propTypes = { placeholder: string };

export type Props = InferProps<typeof propTypes> & CommonInputProps;

FwFileInput.propTypes = propTypes;

export default FwFileInput;
