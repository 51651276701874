import { InferProps, number } from 'prop-types';

import { ctor } from '../helper';

const fwGridItemPT = {
  column: number,
  columnSpan: number,
  row: number,
  rowSpan: number,
};

type FwGridItemPT = InferProps<typeof fwGridItemPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwGridItemProps extends FwGridItemPT {}
class FwGridItemProps implements FwGridItemPT {
  constructor(props?: FwGridItemPT) {
    ctor(this, props);
  }
}

export { fwGridItemPT, FwGridItemProps };
