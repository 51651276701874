import _ from 'lodash';
import { func, InferProps } from 'prop-types';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import { EntityInfo } from 'components';
import { useFwAuth } from 'components/base';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { AppPreference, getAdminInputs, Input } from 'core/model';
import { readSettings } from 'core/utils/storage';

const Preferences: FC<Props> = ({ handleSetKey, handleEntityChange }) => {
  const { t } = useTranslation();

  const { user } = useFwAuth();
  const settings = readSettings();

  const stateRef = useRef(
    new AppPreference({
      appPreferenceID: user.appPreference.appPreferenceID,
      ...settings,
    })
  );

  // todo consider moving this to EntityInfo
  const inputs = getAdminInputs(stateRef.current, null, t);
  const inputsRef = useRef(inputs);

  const entityDoc = getEntityDoc(stateRef.current, inputs);

  const onDataChange = useCallback(
    (data) => {
      // todo wip #585 refactor value type mapping
      // turn data (string values) into newData (typed values)
      // based on input types and field keys
      const newData = _.reduce(
        data,
        (result, value, key) => {
          const inputType = (_.find(inputsRef.current, { key }) as Input)?.type;
          result[key] =
            inputType === 'CHECKBOX'
              ? value === 'true'
              : value; /* map value here */
          return result;
        },
        {}
      );

      stateRef.current = new AppPreference(_.assign(stateRef.current, newData));
      handleEntityChange(newData);
    },
    [handleEntityChange]
  );

  return (
    <EntityInfo
      autosave
      entityDoc={entityDoc}
      icon="RiSoundModuleFill"
      label={t('common|Preferences')}
      api={appPreferenceApi}
      entityKey={stateRef.current.appPreferenceID}
      keyName={'appPreferenceID'}
      type={'appPreference'}
      onEntityChange={onDataChange}
      onSetKey={handleSetKey}
    />
  );
};

const propTypes = {
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

Preferences.propTypes = propTypes;

export default withTranslation()(Preferences);
