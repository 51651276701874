import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FwIcon, FwParagraph, FwTooltip } from 'components/base';
import { fwItemPT, FwItemProps } from 'core/model';

import { useFwItemStyle } from './FwItem.helpers';

const FwItem: FC<FwItemProps> = ({
  children,
  active,
  chubby,
  color,
  center,
  leftIcon,
  leftIconColor,
  small,
  text,
  tooltip,
  onClick,
}) => {
  const { _hover, ...style } = useFwItemStyle(active);

  const itemHeight = chubby
    ? small
      ? '3rem'
      : '3.5rem'
    : small
    ? '1.3rem'
    : '1.5rem';

  const itemStyle = {
    align: 'center',
    px: '14px',
    _hover: onClick ? _hover : undefined,
    h: itemHeight,
    lineHeight: itemHeight,
    justifyContent: center ? 'center' : undefined,
    ...style,
  };

  const textStyle = {
    color,
    small,
  };

  return (
    <FwTooltip text={tooltip}>
      <Flex {...itemStyle} onClick={onClick}>
        <FwParagraph truncated {...textStyle}>
          {leftIcon && <FwIcon inline name={leftIcon} color={leftIconColor} />}
          {text}
          {children}
        </FwParagraph>
      </Flex>
    </FwTooltip>
  );
};

FwItem.propTypes = fwItemPT;

export default FwItem;
