import { Context, createContext, Dispatch, SetStateAction } from 'react';

type PageStore = {
  // todo #597 to define
  // processData: ProcessDataStore;
  // ...other data
};

type PageStoreContext = {
  pageStore: PageStore;
  setPageStore: Dispatch<SetStateAction<PageStore>>;
};

const FwPageStoreContext: Context<PageStoreContext> =
  createContext<PageStoreContext>(undefined);

export { PageStore, FwPageStoreContext };
