import _ from 'lodash';
import { bool, InferProps, number, string } from 'prop-types';
import React, { FC, useEffect, useRef } from 'react';

import { FIELD_TYPE } from 'core/utils/constant';

import FwPop from '../../pop/FwPop';
import { useDateFwPop } from '../../pop/useFwPop';
import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';
import { Picker } from './components';
import { isValidDateString } from './FwInput.Datetime.helpers';

const { date, datetime, time } = FIELD_TYPE;

const Datetime: FC<Props> = (props) => {
  const {
    // direct props
    multiple,
    pattern,
    range,
    step,
    // common input props
    defaultValue: defaultValProp,
    type = date,
  } = props;

  const timeRangeRef = useRef(type === time && range ? [] : undefined);

  const unsupportedCase =
    // these props combinations are not yet implemented
    (multiple && range) ||
    (_.includes([datetime, time], type) && multiple) ||
    (_.includes([datetime], type) && range);

  const { isValid, validatedDates } = isValidDateString(defaultValProp, type);

  const {
    contentValue,
    baseInputProps,
    forceContentValue,
    isOpen,
    popProps,
    setContentValue,
  } = useDateFwPop(props, {
    rightIcon: isValid ? 'RiFocus3Line' : 'RiCalendarFill',
    onRightIconClick: isValid
      ? () => {
          forceContentValue(validatedDates);
        }
      : undefined,
  });

  useEffect(() => {
    if (type === time && range) {
      timeRangeRef.current = contentValue;
    }
  }, [contentValue, type, range]);

  // react-datepicker lib sends selection as...
  // ...array of date objects (when picking range) or date object (otherwise)
  const handleDateChange = (selection: Date | Date[]) => {
    // update contentValue
    if (range) {
      if (type === time) {
        // react-datepicker lib doesn't support time range picker so we need to build an array for it to works
        const [start, end]: Date[] = timeRangeRef.current;
        const newTimeRange =
          !start || start > selection || end ? [selection] : [start, selection];

        forceContentValue(newTimeRange);
      } else {
        forceContentValue(selection);
      }
    } else {
      setContentValue(selection);
    }
  };

  return unsupportedCase ? null : (
    <FwPop {...popProps}>
      <FwPop.Anchor>
        <Base {...baseInputProps} />
      </FwPop.Anchor>
      <FwPop.Content>
        {/* delay render of picker until pop opens */}
        {isOpen && (
          <Picker
            multiple={multiple}
            pattern={pattern}
            range={range}
            step={step}
            type={type}
            value={contentValue}
            onChange={handleDateChange}
          />
        )}
      </FwPop.Content>
    </FwPop>
  );
};

const propTypes = {
  multiple: bool,
  pattern: string,
  placeholder: string,
  range: bool,
  step: number,
};

export type Props = InferProps<typeof propTypes> & CommonInputProps;

Datetime.propTypes = propTypes;

export default Datetime;
