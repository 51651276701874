import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { Filter } from 'core/model';

type ProcessDataStoreValue = {
  filters?: Filter[];
  edits?: { [key: string]: string };
  result?: any; // todo #597 to define
};

type ProcessDataStore = { [key: number]: ProcessDataStoreValue };

type PageContentStore = {
  processData?: ProcessDataStore;
  selections?: string[];
  // ...other data like table filters
};

type PageContentStoreContext = {
  pageContentStore: PageContentStore;
  setPageContentStore: Dispatch<SetStateAction<PageContentStore>>;
};

const FwPageContentStoreContext: Context<PageContentStoreContext> =
  createContext<PageContentStoreContext>(undefined);

export { PageContentStore, FwPageContentStoreContext };
