import { useColorMode, useTheme } from '@chakra-ui/react';
import { mode, transparentize } from '@chakra-ui/theme-tools';

import { fwAccent } from 'config/theme/constants';

const useFwTheme = (bgOpacity?: number) => {
  const themeObject = useTheme();
  const colorModeContext = useColorMode();

  // get theme colors directly from Chakra UI
  const { global } = themeObject['styles'];
  const { bg, color } = global(colorModeContext).body;
  const { bg: darkModeBg, color: darkModeColor } = global({
    colorMode: 'dark',
  }).body;
  const { bg: lightModeBg, color: lightModeColor } = global({
    colorMode: 'light',
  }).body;

  // refactor use of mode and transparentize utilities
  const fwMode = (light: string, dark: string) =>
    mode(light, dark)(colorModeContext);
  const fwTransparentize = (color: string, opacity: number) =>
    transparentize(color, opacity)(themeObject);

  // add opacity to background color
  const newBg: string = bgOpacity
    ? `${transparentize(bg, bgOpacity)(themeObject)}`
    : bg;

  return {
    bg: newBg,
    color,
    accent: fwAccent,
    borderColor: fwMode(
      'var(--chakra-colors-gray-100)',
      'var(--chakra-colors-gray-700)'
    ),
    boxShadow: fwMode(
      'inset 1px 0 0 var(--chakra-colors-blackAlpha-400)',
      'inset 1px 0 0 var(--chakra-colors-whiteAlpha-400)'
    ),
    _dark: {
      bg: darkModeBg,
      color: darkModeColor,
    },
    _light: {
      bg: lightModeBg,
      color: lightModeColor,
    },
    _disabled: {
      bg: fwMode('rgba(0, 0, 0, 0.02)', 'var(--chakra-colors-whiteAlpha-50)'),
      boxShadow: fwMode(
        'inset 1px 0 0 var(--chakra-colors-blackAlpha-200)',
        'inset 1px 0 0 var(--chakra-colors-whiteAlpha-200)'
      ),
      color: fwMode(
        'var(--chakra-colors-blackAlpha-600)',
        'var(--chakra-colors-whiteAlpha-600)'
      ),
      _hover: {
        bg: fwMode(
          'rgba(0, 0, 0, 0.05)',
          'var(--chakra-colors-whiteAlpha-200)'
        ),
        color: fwMode(
          'var(--chakra-colors-gray-600)',
          'var(--chakra-colors-gray-300)'
        ),
      },
    },
    _hover: {
      bg: fwMode(
        fwTransparentize(`${fwAccent}.50`, 0.9),
        fwTransparentize(`${fwAccent}.200`, 0.1)
      ),
    },
    // active must come after _hover to be able to override it
    _active: {
      bg: fwMode(
        fwTransparentize(`${fwAccent}.100`, 0.75),
        fwTransparentize(`${fwAccent}.200`, 0.25)
      ),
      boxShadow: `var(--chakra-colors-${fwAccent}) 0.17rem 0px 0px -0.02rem inset !important`,
    },
  };
};

export { useFwTheme };
