import { InferProps } from 'prop-types';
import React, { FC, useState } from 'react';
// import utils from 'core/utils/utils';

import { ModuleStore, FwModuleStoreContext } from './FwModuleStoreContext';

const FwModuleStoreProvider: FC<Props> = ({ children }) => {
  const [moduleStore, setModuleStore] = useState<ModuleStore>({});

  return (
    <FwModuleStoreContext.Provider
      value={{
        moduleStore,
        setModuleStore,
      }}
    >
      {children}
    </FwModuleStoreContext.Provider>
  );
};

const propTypes = {};

type Props = InferProps<typeof propTypes>;

FwModuleStoreProvider.propTypes = propTypes;

export { FwModuleStoreProvider };
