import { FormControl, FormLabel } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwIcon, FwInput, FwCollection } from 'components/base';
import { FwFieldProps, fwFieldPT } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

const { checkbox, collection } = FIELD_TYPE;

const FwField: FC<FwFieldProps> = ({
  collectionValue,
  invalid,
  invalidSubInputKey,
  invalidRow,
  label: lblProp,
  loadingSubInputKeys,
  noLabel,
  subInputs,
  tooltip,
  type,
  ...props
}) => {
  const { readOnly, required, disabled } = props;
  const { t } = useTranslation();

  const label = lblProp ? t(`custom|${lblProp}`) : '';
  const checkboxFieldLabel = type === checkbox ? '|' : undefined;

  const labelStyle = {
    fontSize: 12,
    mb: 0,
    // hide checkbox field label but maintain structure
    color: type === checkbox ? 'transparent' : undefined,
    // conditionally remove required indicator if the field is a checkbox
    ...(type === checkbox ? { requiredIndicator: null } : {}),
  };

  const formControlProps = {
    isDisabled: disabled || readOnly,
    isInvalid: invalid,
    isReadOnly: readOnly,
    isRequired: required,
  };

  return (
    <FormControl position="unset" {...formControlProps}>
      {!(noLabel && !tooltip && !required) && (label || tooltip) && (
        <FormLabel {...labelStyle} onClick={(e) => e.preventDefault()}>
          {tooltip ? (
            <FwIcon primary inline name="RiInformationLine" tooltip={tooltip} />
          ) : null}
          {checkboxFieldLabel || label}
        </FormLabel>
      )}
      {type === collection ? (
        <FwCollection
          {...props}
          subInputs={subInputs}
          collectionValue={collectionValue}
          invalid={invalid}
          invalidSubInputKey={invalidSubInputKey}
          invalidRow={invalidRow}
          loadingSubInputKeys={loadingSubInputKeys}
        />
      ) : (
        <FwInput
          {...props}
          required={required}
          label={type === checkbox ? label : undefined}
          type={type}
        />
      )}
    </FormControl>
  );
};

FwField.propTypes = fwFieldPT;

// pros/cons when use deep compare _.isEqual
// + trigger render for case collection/reference input which contains subInputs and visible as sub-props
// + avoid render for collection/reference input when others input types has changed (shallow compare will always return false when compare {} or [])
// - it might trigger unnecessary render for others types than collection/reference when their props has changed
//
export default React.memo(FwField, _.isEqual);
