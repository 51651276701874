import {
  chakra,
  Checkbox,
  Code,
  Divider,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { Components } from 'react-markdown';

import { FwIframe, FwLink } from 'components/base';

type GetCoreProps = {
  children?: React.ReactNode;
  'data-sourcepos'?: any;
};

type listRenderProps = {
  children?: React.ReactNode;
  depth?: number;
  ordered?: boolean;
  'data-sourcepos'?: any;
};

const getCoreProps = (props: GetCoreProps) => {
  return props['data-sourcepos']
    ? { 'data-sourcepos': props['data-sourcepos'] }
    : {};
};

const listRender = (props: listRenderProps) => {
  const { children, depth, ordered } = props;
  const Element = ordered ? OrderedList : UnorderedList;
  const styleType = ordered ? 'decimal' : depth === 1 ? 'circle' : 'disc';
  return (
    <Element
      spacing={2}
      as={ordered ? 'ol' : 'ul'}
      styleType={styleType}
      pl={4}
      {...props}
      {...getCoreProps(props)}
    >
      {children}
    </Element>
  );
};

const urlChecker = (url: string) => {
  //is local
  const isLocal = url.includes(window.location.origin);

  //is valid
  const urlPattern = new RegExp(
    '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$'
  );

  const isValid = urlPattern.test(url);

  return isLocal || isValid;
};

interface Defaults extends Components {
  /**
   * @deprecated Use `h1, h2, h3, h4, h5, h6` instead.
   */
  heading?: Components['h1'];
}

export const defaults: Defaults = {
  a: ({ children, href }) =>
    urlChecker(href) && <FwLink to={href}>{children}</FwLink>,
  blockquote: ({ children, style }) => (
    <Code as="blockquote" p={2} style={style}>
      {children}
    </Code>
  ),
  code: ({ className, children, inline, style }) => {
    const codeStyle = inline
      ? undefined
      : {
          'white-space': 'break-spaces',
          display: 'block',
          w: 'full',
        };

    return (
      <Code {...codeStyle} className={className} p={2} style={style}>
        {children}{' '}
      </Code>
    );
  },
  del: ({ children, style }) => (
    <Text as="del" style={style}>
      {children}
    </Text>
  ),
  em: ({ children, style }) => (
    <Text as="em" style={style}>
      {children}
    </Text>
  ),
  heading: (props) => {
    const { children, level, style } = props;
    const size = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'][level - 1];
    return (
      <Heading
        my={4}
        as={('h' + level.toString()) as any}
        size={size}
        style={style}
        {...getCoreProps(props)}
      >
        {children}
      </Heading>
    );
  },
  hr: Divider,
  iframe: ({ src }) => urlChecker(src) && <FwIframe url={src} />,
  img: Image,
  li: (props) => (
    <ListItem
      listStyleType={_.isNull(props.checked) ? 'none' : 'inherit'}
      {...getCoreProps(props)}
    >
      {_.isNil(props.checked) ? (
        <Checkbox isChecked={props.checked} isReadOnly style={props.style}>
          {props.children}
        </Checkbox>
      ) : (
        props.children
      )}
    </ListItem>
  ),
  ol: (props) => listRender(props),
  p: ({ children, style }) => (
    <Text mb={2} style={style}>
      {children}
    </Text>
  ),
  pre: (props) => (
    <chakra.pre {...getCoreProps(props)}>{props.children}</chakra.pre>
  ),
  table: Table,
  tbody: Tbody,
  td: ({ children }) => <Td>{children}</Td>,
  th: ({ children }) => <Th>{children}</Th>,
  text: ({ children, style }) => (
    <Text as="span" style={style}>
      {children}
    </Text>
  ),
  thead: Thead,
  tr: ({ children }) => <Tr>{children}</Tr>,
  ul: (props) => listRender(props),
};

const UIRenderer = (theme?: Defaults, merge = true) => {
  const elements = {
    a: defaults.a,
    blockquote: defaults.blockquote,
    code: defaults.code,
    del: defaults.del,
    em: defaults.em,
    h1: defaults.heading,
    h2: defaults.heading,
    h3: defaults.heading,
    h4: defaults.heading,
    h5: defaults.heading,
    h6: defaults.heading,
    hr: defaults.hr,
    iframe: defaults.iframe,
    img: defaults.img,
    li: defaults.li,
    ol: defaults.ol,
    p: defaults.p,
    pre: defaults.pre,
    table: defaults.table,
    tbody: defaults.tbody,
    td: defaults.td,
    text: defaults.text,
    th: defaults.th,
    thead: defaults.thead,
    tr: defaults.tr,
    ul: defaults.ul,
  };

  if (theme && merge) {
    return _.merge(elements, theme);
  }

  return elements;
};

export default UIRenderer;
