import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteChildrenProps } from 'react-router';

import { useApi } from 'api';
import userApi from 'api/user/userApi';
import { FwSpinner, useFwArea, useFwSettings } from 'components/base';
import { AppPreference, AppUser, Identity } from 'core/model';
import { clearStorage, getToken, setToken } from 'core/utils/storage';
import utils from 'core/utils/utils';

import { FwAuthContext, UserFilters } from './FwAuthContext';

const FwAuthProvider: FC<Partial<RouteChildrenProps>> = ({
  children,
  history,
  location,
}) => {
  const storedToken = getToken();
  const sentToken =
    !storedToken &&
    // no token in local storage
    // try to retrieve from url search query to identify user)
    _.includes(location?.search, 'token')
      ? location.search.replace('?token=', '')
      : undefined;
  const authToken = storedToken || sentToken;
  const tokenRef = useRef(authToken);

  if (!storedToken && sentToken) {
    setToken(authToken);
  }

  const { area, getAreaBasePath } = useFwArea();
  const { dispatchAppPreference } = useFwSettings();

  const [identity, setIdentity] = useState<Identity>();
  const [userFilters, setUserFilters] = useState<UserFilters>();

  const argsUser = useRef([]);
  const {
    fetched,
    pending,
  }: { fetched: { appUser: AppUser }; pending: boolean } = useApi(
    !authToken ? null : userApi.getCurrent,
    argsUser.current
  );

  useEffect(() => {
    // after login, token must be stored in local storage
    // identity can be fetched from database
    if (!pending && fetched) {
      setIdentity(
        new Identity({
          token: tokenRef.current,
          new: false,
          ...fetched.appUser,
        } as Identity)
      );

      setUserFilters({
        fixedFilters: _.filter(fetched.appUser.filters, (f) => !f.editable),
        editableFilters: _.filter(fetched.appUser.filters, (f) => f.editable),
      });
    }
  }, [pending, fetched]);

  useEffect(() => {
    // when user was authnticated, override app settings by user preferences
    tokenRef.current = identity?.token;
    dispatchAppPreference(new AppPreference(identity?.appPreference));
  }, [identity, dispatchAppPreference]);

  // log user out by clearing loacal storage and redirecting to login page
  const logout = (refreshRedirect?: boolean) => {
    clearStorage();

    const path = getAreaBasePath(area);

    if (refreshRedirect) {
      // redirect and fetch page from site server
      window.location.href = path;
    } else {
      // todo remove hardcoded url
      history.push(path);
    }
  };

  // once user if authenticated...
  // ...make sure authorizations are sufficient to access default page
  const checkUserAccess = () => {
    if (
      !identity ||
      (!area && identity && !utils.canAccess(identity, 'main'))
    ) {
      logout();
    }
  };

  return pending ? (
    <FwSpinner />
  ) : (
    <FwAuthContext.Provider
      value={{
        user: identity,
        userFilters,
        setUserFilters,
        checkUserAccess,
        logout,
      }}
    >
      {children}
    </FwAuthContext.Provider>
  );
};

export { FwAuthProvider };
