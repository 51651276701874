import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  chakra,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwButton,
  FwField,
  FwFilter,
  FwGrid,
  FwMessage,
} from 'components/base';
import { mapInputToField } from 'core/mapper';
import { FwModalProps, fwModalPT } from 'core/model';

const FwModal: FC<FwModalProps> = ({
  additionalData,
  blur = true,
  cancelName,
  content,
  data,
  filters,
  inputs,
  invalidInputKey,
  loadingInputKeys,
  message,
  name,
  noDimmerClick,
  open,
  submitName,
  type,
  warning,
  onCancel,
  onChangeData,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const initialRef = useRef();

  const { closeOnDimmerClick } = additionalData || {};

  const [disabled, setDisabled] = useState(false);

  const overrideOnSubmit = () => {
    setDisabled(true);

    if (onSubmit) {
      onSubmit();
    }
  };

  const overrideOnCancel = () => {
    setDisabled(true);
    systemClose();
  };

  const overrideOnChangeData = (e, htmlData) => {
    setDisabled(false);
    onChangeData(e, htmlData);
  };

  const systemClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const AnchorFocus = () => <chakra.input ref={initialRef} height={0} />;

  return (
    <Modal
      isCentered
      isOpen={open}
      onClose={systemClose}
      initialFocusRef={initialRef}
      autoFocus={false}
      closeOnOverlayClick={
        !_.isNil(closeOnDimmerClick) ? closeOnDimmerClick : !noDimmerClick
      }
    >
      <ModalOverlay backdropFilter={blur ? 'blur(5px)' : undefined} />
      <ModalContent>
        <AnchorFocus />
        <FwMessage subtle message={name} type={type} />
        {(message || inputs || filters || content) && (
          <ModalBody>
            {message && t(message, { ns: ['glossary', 'common', 'custom'] })}
            {inputs && inputs.length > 0 && (
              <FwGrid
                itemComponent={FwField}
                items={_.map(inputs, (i) =>
                  mapInputToField(i, {
                    docData: data,
                    invalidInputKey,
                    loadingInputKeys,
                    onChange: overrideOnChangeData,
                  })
                )}
              />
            )}
            {filters && filters.length > 0 && (
              <FwGrid
                itemComponent={FwFilter}
                items={_.map(filters, (i, index) =>
                  mapInputToField(i, {
                    filterData: data[index],
                    invalidInputKey,
                    onChange: (e, data) => {
                      setDisabled(false);
                      onChangeData(e, data, index);
                    },
                  })
                )}
              />
            )}
            {content && <FwGrid>{content}</FwGrid>}
          </ModalBody>
        )}
        {warning && (
          <FwMessage
            small
            type="error"
            message={t(warning, { ns: ['glossary', 'common'] })}
          />
        )}
        <ModalFooter>
          <ButtonGroup>
            {submitName && (
              <FwButton
                primary
                small
                disabled={disabled}
                onClick={overrideOnSubmit}
              >
                {t(`common|${submitName}`)}
              </FwButton>
            )}
            {cancelName && (
              <FwButton small disabled={disabled} onClick={overrideOnCancel}>
                {t(`common|${cancelName}`)}
              </FwButton>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

FwModal.propTypes = fwModalPT;

export default FwModal;
