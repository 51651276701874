import {
  chakra,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FwInput, FwSpinner, useFwTheme } from 'components/base';
import { getDateCriteria } from 'components/tables/helpers/tableCriteriaHelpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE } from 'core/utils/constant';
import {
  dateFormats,
  getDateOrDefault,
  isSameDay,
  jsDateFromString,
  jsDateToString,
  initDateTime,
} from 'core/utils/date';
import useClickAwayListener from 'core/utils/useClickAwayListener';
import utils from 'core/utils/utils';

import { TimelineProgress } from './components';
import { maskRowsToTimelineData } from './FwMask.Timeline.helpers';

const FwTimeline: FC<FwMaskCommonProps> = ({
  maskStructure,
  maskRows,
  loading,
  updateCriteriaFunc,
  ...props
}) => {
  // todo wip#585 use compact to make table scroll vertical
  const { view, document /*, compact*/ } = maskStructure;
  const { date } = view || {};

  const { t } = useTranslation();
  const timelineTableRef = useRef();
  const selectedDateRef = useRef(new Date());

  // todo wip#585 refactor with Row.tsx
  const {
    _active: { bg },
    _hover,
  } = useFwTheme();

  const [activeItem, setActiveItem] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(
    getDateOrDefault(jsDateFromString(date, dateFormats.isoDate))
  );
  const [timeline, setTimeline] = useState([]);
  const [headerData, setHeaderData] = useState({
    timeCells: [],
    slots: 0,
  });

  useEffect(() => {
    selectedDateRef.current = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    if (maskStructure && maskRows) {
      const { start, end } = maskStructure.view || {};
      const timelineData = maskRowsToTimelineData(maskStructure, maskRows, t);

      // pass all the time values to override all the time's parts
      const currentDateStartView = initDateTime(
        selectedDateRef.current,
        start,
        0,
        0,
        0
      );
      const currentDateEndView = initDateTime(
        selectedDateRef.current,
        end,
        0,
        0,
        0
      );

      // keep only event which is between the timerange's view limit: (startView, endView)
      _.each(timelineData, (d) => {
        d.labels = _.filter(
          d.labels,
          (lbl) =>
            lbl.end > currentDateStartView && lbl.start < currentDateEndView
        );
      });

      // remove timeline which has 0 event
      setTimeline(_.filter(timelineData, (d) => d.labels.length > 0));
    }
  }, [maskStructure, maskRows, t]);

  useEffect(() => {
    if (maskStructure) {
      const { start, end } = maskStructure.view || {};
      const timeStart =
        !_.isNil(start) && Number.isInteger(start) && start > 0 && start < 23
          ? start
          : 9;
      const timeEnd =
        !_.isNil(end) && Number.isInteger(end) && end > 1 && end < 24
          ? end
          : 19;
      const slots = (timeEnd - timeStart + 1) * 2;

      const timeCells = [];

      for (let i = timeStart; i < timeEnd + 1; i++) {
        timeCells.push(
          <Th
            textAlign="center"
            key={i}
            colSpan={2}
            whiteSpace="nowrap"
            pt={'0.3em'}
            pb={'0.3em'}
            maxW="80px"
          >
            {jsDateToString(
              jsDateFromString(
                `${utils.toHourString(i)}:00`,
                dateFormats.isoTime
              ),
              dateFormats.time
            )}
          </Th>
        );
      }

      setHeaderData({ timeCells, slots });
    }
  }, [maskStructure]);

  useClickAwayListener(timelineTableRef, activeItem, () => {
    handleRowClick(undefined);
  });

  // define functions
  const handleRowClick = (key: string) => {
    setActiveItem(key);
  };

  const handleDateChange = (newValue: string) => {
    const { start, end } = document;
    const newDate = jsDateFromString(newValue, dateFormats.isoDate);

    // set state
    setSelectedDate(newDate);

    // if date has changed, update query
    if (!isSameDay(selectedDate, newDate) && (start || end)) {
      const dateCriteria = getDateCriteria(document, newDate);
      updateCriteriaFunc(dateCriteria);
    }
  };

  const { slots, timeCells } = headerData;

  // todo #585 refactor styling?
  const tableContainerStyle = {
    mb: 2,
  };

  // todo #585 refactor styling?
  const tableStyle = {
    size: 'sm',
    sx: {
      'thead, tbody, tr, th, td': { border: 'none !important' },
      'td:not(:first-of-type)': { paddingLeft: 0, paddingRight: 0 },
    },
  };

  return (
    <>
      <Grid alignItems="center" templateColumns="repeat(3, 1fr)" mb={2}>
        <GridItem />
        <GridItem />
        <GridItem>
          <FwInput
            type={FIELD_TYPE.date}
            value={jsDateToString(selectedDate, dateFormats.isoDate)}
            onChange={(_e, { value }) => handleDateChange(value as string)}
          />
        </GridItem>
      </Grid>
      <TableContainer {...tableContainerStyle}>
        <Table ref={timelineTableRef} {...tableStyle}>
          <Thead>
            <Tr>
              <Th rowSpan={2} />
              <Th colSpan={slots} />
            </Tr>
            <Tr>{timeCells}</Tr>
          </Thead>
          <Tbody>
            {loading && (
              <Tr>
                <Td colSpan={slots}>
                  <FwSpinner />
                </Td>
              </Tr>
            )}
            {!loading && _.isEmpty(timeline) && (
              <Tr>
                <Td colSpan={slots}>{t('No records found')}</Td>
              </Tr>
            )}
            {!loading &&
              timeline.length > 0 &&
              _.map(timeline, (d) => {
                const active = activeItem === d.name;
                // todo wip#585 refactor with Row.tsx
                const rowStyle = {
                  bg: active ? bg : undefined,
                  _hover:
                    // (prevent hover from overriding active style, despite :hover pseudo-element)
                    _.merge(_hover, active ? { bg } : {}),
                };

                return (
                  <Tr
                    {...rowStyle}
                    key={d.name}
                    onMouseDown={() => handleRowClick(d.name)}
                    onMouseUp={() => handleRowClick(d.name)}
                  >
                    <Td>
                      {d.name ? (
                        d.name
                      ) : (
                        // prevent cell height collapse when no data
                        <chakra.b color="transparent">...</chakra.b>
                      )}
                    </Td>
                    <TimelineProgress
                      {...props}
                      selectedDate={selectedDate}
                      view={view}
                      data={d}
                      slots={slots}
                    />
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

FwTimeline.propTypes = {};

export default FwTimeline;
