import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

import { updateRefFieldDataRelated } from '.';

const buildRefDataInCollection = (doc: any, data, refAndOtherInputs, i) => {
  const item = {};

  _.forEach(refAndOtherInputs, ({ key, type, additionalData }) => {
    // build key
    const subFieldKeyFormat = `${key}|${i}`;

    if (type === FIELD_TYPE.reference) {
      // assign to item the values of refInput and relatedRefInputs
      updateRefFieldDataRelated(doc, data, key, additionalData, item, i);
    } else {
      // assign to item
      item[key] = data[subFieldKeyFormat];
    }

    // remove data from docData
    delete data[subFieldKeyFormat];
  });

  return item;
};

const updateDocDataOnLoad = (doc, data) => {
  // process collection/reference data if has
  const templateInputs = utils.getDistinctFieldsFromTemplates([doc.template]);

  // process collection data
  _.forEach(
    _.filter(templateInputs, (i) => i.type === FIELD_TYPE.collection),
    ({ key, subInputs }) => {
      const collectionData = [];
      const collectionLength = data[key] ? parseInt(data[key]) : 0;

      if (collectionLength > 0) {
        const allRefExtendInputs = [];

        if (subInputs.some((sf) => sf.type === FIELD_TYPE.reference)) {
          // has reference fields inside collection field
          const refSubInputs = subInputs.filter(
            (f) => f.type === FIELD_TYPE.reference
          );

          // get all referenceFields and linkFields of each input type reference
          const extendRefInputs = subInputs
            .filter((f) =>
              refSubInputs.some((i) => f.key.startsWith(`${i.key}|`))
            )
            .map((i) => i.key);

          allRefExtendInputs.push(...extendRefInputs);
        }

        // filter out referenceFields and linkFields in this subInputs
        const refAndOtherInputs = subInputs.filter(
          (i) => !allRefExtendInputs.includes(i.key)
        );

        // build data
        for (let i = 1; i <= collectionLength; i++) {
          const item = buildRefDataInCollection(
            doc,
            data,
            refAndOtherInputs,
            i
          );

          collectionData.push(item);
        }
      }

      // update built data back to collection field
      data[key] = collectionData;
    }
  );

  // process reference data by getting all data relation
  _.forEach(
    _.filter(templateInputs, (i) => i.type === FIELD_TYPE.reference),
    ({ key, additionalData }) => {
      updateRefFieldDataRelated(doc, data, key, additionalData);
    }
  );
};

export default updateDocDataOnLoad;
