import { Tooltip, PlacementWithLogical } from '@chakra-ui/react';
import { any, InferProps } from 'prop-types';
import React, { FC } from 'react';

const FwTooltip: FC<Props> = ({ children, text, placement = 'auto' }) => {
  // for default animation parameters, see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/tooltip/src/tooltip.transition.tsx

  const style = {
    openDelay: 750,
    closeDelay: 0,
    opacity: '0.85 !important',
  };

  // must contain enter and exit. See Line 151 to 153 here:
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/tooltip/src/tooltip.tsx#L151
  const framerProps = {
    variants: {
      // animations
      enter: {
        scale: 1,
        opacity: 1,
        transition: {
          opacity: { easings: 'easeOut', duration: 0.2 },
          scale: { duration: 0.2, ease: [0.175, 0.885, 0.4, 1.1] },
        },
      },
      exit: {
        scale: 0.9,
        opacity: 0,
        transition: {
          opacity: { duration: 0.001, easings: 'easeInOut' },
          scale: { duration: 0.001, easings: 'easeInOut' },
        },
      },
    },
  };

  return (
    <Tooltip
      {...style}
      hasArrow
      placement={placement}
      label={text}
      {...framerProps}
    >
      {children ? children : <></>}
    </Tooltip>
  );
};

const propTypes = {
  children: any,
  // todo #585 rename text to content
  text: any,
};

export interface Props extends InferProps<typeof propTypes> {
  placement?: PlacementWithLogical;
}
FwTooltip.propTypes = propTypes;

export default FwTooltip;
