import { chakra } from '@chakra-ui/react';
import { any, bool, func, InferProps, string } from 'prop-types';
import React, { FC } from 'react';
import * as Icons from 'react-icons/ri';

import { FwTooltip } from 'components/base';
import { useFwCssStyle, useFwTheme } from 'components/base/utility';

const FwIcon: FC<Props> = ({
  color: colorProp,
  disabled,
  inline,
  name,
  primary,
  tooltip,
  ...props
}) => {
  let render: JSX.Element;
  const { accent } = useFwTheme();
  const { color } = useFwCssStyle({ color: colorProp });

  const IconComponent = Icons[name];

  if (!IconComponent) {
    render = name ? <>{name}</> : <></>;
  } else {
    // pass icon to chakra factory
    const Icon = chakra(IconComponent);

    // define icon style
    const iconStyle = {
      color: color || (primary ? accent : undefined),
      display: inline ? 'inline-block' : undefined,
      mr: inline ? '6px' : undefined,
      mb: inline ? '-2px' : undefined,
      flexShrink: 0,
      opacity: disabled ? 0.4 : undefined,
    };

    // render
    render = <Icon {...iconStyle} {...props} />;
  }

  return tooltip ? (
    <FwTooltip text={tooltip}>
      <span>{render}</span>
    </FwTooltip>
  ) : (
    render
  );
};

const propTypes = {
  attr: any,
  className: string,
  color: string,
  disabled: bool,
  inline: bool,
  name: string,
  primary: bool,
  size: string,
  style: any,
  tooltip: string,
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

FwIcon.propTypes = propTypes;

export default FwIcon;
