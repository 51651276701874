import {
  arrayOf,
  InferProps,
  instanceOf,
  number,
  oneOfType,
  string,
} from 'prop-types';

// https://gitlab.com/fastercom-repositories/fasterweb/-/wikis/Unstructured-data#view-contents-masking-grids

// mask views
const timelineViewTypes = {
  date: string,
  start: number,
  end: number,
};

type TimelineView = InferProps<typeof timelineViewTypes>;

const agendaViewTypes = {
  type: string,
  slots: number,
  step: number,
};

type AgendaView = InferProps<typeof agendaViewTypes> & TimelineView;

export const mapViewTypes = {
  center: [number, number],
  zoom: number,
  geo: Object,
};

export type MapView = InferProps<typeof mapViewTypes>;

// mask documents
export enum TextTypes {
  static = 'STATIC',
  fieldKey = 'FIELDKEY',
}

export class TextObject {
  text: string;
  type: string;
}

const commonDocumentTypes = {
  text: oneOfType([
    arrayOf(arrayOf(string)),
    arrayOf(arrayOf(instanceOf(TextObject))),
  ]),
};

type CommonDocument = InferProps<typeof commonDocumentTypes>;

const agendaDocumentTypes = {
  groupBy: string,
  start: arrayOf(string),
  length: string,
  end: arrayOf(string),
};

type AgendaDocument = InferProps<typeof agendaDocumentTypes> & CommonDocument;

const cardsDocumentTypes = {};

type CardsDocument = InferProps<typeof cardsDocumentTypes> & CommonDocument;

const mapDocumentTypes = {
  geo: arrayOf(string),
  longitude: string,
  latitude: string,
  markerText: string,
};
export type MapDocument = InferProps<typeof mapDocumentTypes>;

const timelineDocumentTypes = {};

type TimelineDocument = InferProps<typeof timelineDocumentTypes> &
  AgendaDocument;

export interface MaskStructure {
  compact?: boolean;
  view?: AgendaView & MapView & TimelineView;
  document: AgendaDocument & CardsDocument & MapDocument & TimelineDocument;
}
export class MaskStructure implements MaskStructure {
  constructor(props?: MaskStructure) {
    Object.assign(this, props);
  }
}
