import _ from 'lodash';
import React, { FC } from 'react';
import { Switch, Route, RouteChildrenProps } from 'react-router-dom';

import { Route as DevRoute } from 'core/model';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

// type 'dev' (only for development purposes, accessed from dev tiles)
// import Controls from './scenes/controls/Controls';
import About from './scenes/about/aboutContainer';
import Error from './scenes/error/errorContainer';

const routes: DevRoute[] = [
  // {
  //   path: '/dev/controls',
  //   exact: false,
  //   component: Controls,
  //   name: 'Controls',
  //   icon: 'content',
  // },
  {
    path: '/dev/error',
    exact: true,
    type: 'admin',
    component: Error,
    name: 'Decoding',
    design: { icon: 'RiKey2Fill' },
  },
  {
    path: '/dev/about',
    exact: true,
    type: 'admin',
    component: About,
    name: 'About',
    design: { icon: 'RiFolderUnknowLine' },
  },
];

const renderPageSegment = () => (
  <TilesPanel routes={_.filter(routes, { type: 'admin' })} />
);

const Dev: FC<Partial<RouteChildrenProps>> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} render={renderPageSegment} />
      {routes.map((r, i) => (
        <Route key={i} exact={r.exact} path={r.path} component={r.component} />
      ))}
    </Switch>
  );
};

export default Dev;
