import _ from 'lodash';
import { TFunction } from 'react-i18next';

import { dateFormats, jsDateToString } from 'core/utils/date';

import { getEventStartAndEnd } from '../agenda/FwMask.Agenda.helpers';
import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';

const maskRowsToTimelineData = (
  maskStructure: MaskStructure,
  maskRows: any[],
  t: TFunction
) => {
  const {
    view,
    document: {
      groupBy,
      start: eventStart,
      length: eventLength,
      end: eventEnd,
    },
  } = maskStructure;

  const defaultGroupBy = '';

  _.forEach(
    _.filter(maskRows, (mr) => !mr.data[groupBy]),
    (maskRow) => (maskRow.data[groupBy] = defaultGroupBy)
  );

  const resources = _.sortBy(
    _.uniq(_.map(maskRows, (mr) => mr.data[groupBy])),
    (r) => _.toLower(r)
  );

  const data = _.map(resources, (r) => ({
    name: groupBy ? r : defaultGroupBy,
    view,
    labels: _.map(
      _.filter(maskRows, (mr) => mr.data[groupBy] === r),
      (maskRow) => {
        const { key, data: rowData, color, lastEdit } = maskRow;
        const { start, end } = getEventStartAndEnd(rowData, lastEdit, {
          eventStart,
          eventLength,
          eventEnd,
        });

        // define text
        const text = getMaskRowText(maskStructure, rowData, t);

        return {
          key,
          text,
          color,
          start,
          end,
        };
      }
    ),
  }));

  return data;
};

const dateToTime = (jsDate: Date) => {
  return jsDateToString(jsDate, dateFormats.isoTime);
};

export { maskRowsToTimelineData, dateToTime };
