import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Suggestion } from 'core/model';

const autocompleteApi = {
  getSuggestions: (
    fieldId: string,
    fieldValue: string,
    params: BaseApiParams
  ) =>
    BaseApi.get<{ keyword: string; suggestions: Suggestion[] }>(
      `Suggestion?fieldID=${fieldId}&fieldValue=${fieldValue}`,
      params
    ),
};

export default autocompleteApi;
