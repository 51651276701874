import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Preferences, useInputFormContainer } from 'components';
import {
  FwField,
  FwGrid,
  FwLink,
  FwSegment,
  useFwArea,
  useFwAuth,
} from 'components/base';
import { FwFieldProps } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

const { text } = FIELD_TYPE;

const onChange = () => Function();

const AccountPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { area } = useFwArea();
  const {
    user: currentUser,
    userFilters: { fixedFilters, editableFilters },
  } = useFwAuth();

  const { username } = currentUser;

  const redirectToFilterSelection = () => {
    const path = utils.buildUrlNav(area, null, 'filterselection');
    history.push(`${path}`);
  };

  const showEditFilters = utils.canPerform(currentUser, 'filterselection');

  const infoInputs = [
    {
      key: 'Username',
      name: 'Username',
      label: t('common|Username'),
      type: text,
      isDisabled: true,
      row: 1,
      column: 1,
      subInputs: [],
      visible: true,
    },
    {
      key: 'Role',
      name: 'Role',
      label: t('common|Role'),
      type: text,
      isDisabled: true,
      row: 1,
      column: 2,
      subInputs: [],
      visible: true,
    },
  ];

  const { fields } = useInputFormContainer(
    false,
    undefined,
    { Username: username, Role: utils.getRole(currentUser) },
    infoInputs,
    onChange
  );

  return (
    <FwGrid>
      <FwSegment as={FwGrid} leftIcon="RiUserFill" name={t('common|Info')}>
        <FwGrid items={fields} itemComponent={FwField} />
      </FwSegment>
      {(_.some(fixedFilters) || _.some(editableFilters)) && (
        <FwSegment
          as={FwGrid}
          leftIcon="RiFilter2Fill"
          name={t('common|Filters')}
        >
          {fixedFilters && (
            <FwGrid
              itemComponent={FwField}
              items={_.map(
                fixedFilters,
                ({ key, value }, index) =>
                  new FwFieldProps({
                    key,
                    name: key,
                    label: key,
                    disabled: true,
                    row: index,
                    column: 1,
                    value,
                    onChange,
                  })
              )}
            />
          )}
          {editableFilters && (
            <FwGrid
              itemComponent={FwField}
              items={_.map(
                editableFilters,
                ({ key, value }, index) =>
                  new FwFieldProps({
                    key,
                    name: key,
                    label: key,
                    disabled: true,
                    row: index,
                    column: 1,
                    value,
                    onChange,
                  })
              )}
            />
          )}
          {showEditFilters && (
            <FwLink leftIcon="RiPencilFill" onClick={redirectToFilterSelection}>
              {t('common|Edit filters')}
            </FwLink>
          )}
        </FwSegment>
      )}
      <Preferences />
    </FwGrid>
  );
};

export default AccountPage;
