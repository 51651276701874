import { AxiosResponse } from 'axios';

import baseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, Credentials, Identity } from 'core/model';

const common = async <B extends true | false>(
  username: string,
  password: string,
  initialize: B
): Promise<AxiosResponse | AxiosResponse<{ identity: Identity }>> => {
  const credentials = { username, password };
  const params = { validErrors: [401, 404] };

  return initialize
    ? await baseApi.put(`authentication/initialize`, { credentials }, params)
    : await baseApi.post<{ credentials: Credentials }, { identity: Identity }>(
        `authentication/login`,
        { credentials },
        params
      );
};

const authenticationApi = {
  initialize: async (
    username: string,
    password: string
  ): Promise<AxiosResponse> => {
    return common(username, password, true);
  },
  login: async (
    username: string,
    password: string
  ): Promise<AxiosResponse<{ identity: Identity }>> => {
    return await common(username, password, false);
  },
  signup: async (identity: Identity) => {
    return await baseApi.post(`authentication/register`, { identity });
  },
  post: (identity: Identity, params: BaseApiParams) =>
    baseApi.post(`authentication/register`, identity, params),
  put: (identity: Identity, params: BaseApiParams) =>
    baseApi.put(`authentication`, identity, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<Identity, string>,
    params: BaseApiParams
  ) => baseApi.put('authentication/AS', { autoSaveInfo }, params),
};

export default authenticationApi;
