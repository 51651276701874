import { Stack } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FwIcon, FwList, FwParagraph } from 'components/base';
import { FwCategoryProps, fwCategoryPT } from 'core/model';

const FwCategory: FC<FwCategoryProps> = ({
  items,
  leftIcon,
  name,
  small,
  onChange,
}) => {
  return (
    <Stack spacing={1}>
      <FwParagraph heading small={small}>
        {leftIcon && <FwIcon inline name={leftIcon} />}
        {name}
      </FwParagraph>
      <FwList items={items} small={small} onChange={onChange} />
    </Stack>
  );
};

FwCategory.propTypes = fwCategoryPT;

export default FwCategory;
