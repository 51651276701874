import { InferProps } from 'prop-types';
import React, { FC, useState } from 'react';

import { Page } from 'core/model';

import { FwPageContext } from './FwPageContext';

const FwPageProvider: FC<Props> = ({ children, initPage }) => {
  const [page, setPage] = useState<Page>(initPage);

  return (
    <FwPageContext.Provider value={[page, setPage]}>
      {children}
    </FwPageContext.Provider>
  );
};

const propTypes = {};

interface Props extends InferProps<typeof propTypes> {
  initPage?: Page;
}

FwPageProvider.propTypes = propTypes;

export { FwPageProvider };
