import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableMask,
  Section,
  // imagePreview,
  TrackingByAddress,
} from 'components';
import {
  FwIframe,
  FwSegment,
  FwButton,
  FwPanel,
  FwProcessModal,
  FwPageContentStoreProvider,
  useFwTemplates,
  FwMarkdown,
  FwImage,
} from 'components/base';
import { mapDesignToStyle } from 'core/mapper';
import { BatchTemplate, FwProcessModalProps } from 'core/model';
import { BUTTON_TYPE, CONTENT_TYPE } from 'core/utils/constant';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import History from '../components/History';

const {
  title,
  iframe,
  agenda,
  table,
  map,
  markdown,
  cards,
  trackingByAddress,
  history,
  image,
  timeline,
  section,
  tiles,
} = CONTENT_TYPE;

const CustomPageContent = ({ pc, zoomed, ...props }: any) => {
  const {
    pageContentID,
    tableKey,
    type,
    additionalData,
    processScripts,
    design,
  } = pc || {};
  const { height, small, value } = additionalData || {};

  const { t } = useTranslation();
  const { processes } = useFwTemplates();

  // find processes in context
  const pcProcesses = _.compact(
    _.map(processScripts, ({ processID }) =>
      _.find(processes, (bt) => bt && bt.batchTemplateId === processID)
    )
  );

  // separate pageContent processes and table processes by type
  const pcProcessBtns = _.filter(
    pcProcesses,
    (p) => p.process.type === BUTTON_TYPE.process
  );

  const tableProcessBtns = _.filter(pcProcesses, (p) =>
    [BUTTON_TYPE.edit, BUTTON_TYPE.download].includes(p.process.type)
  );

  const [processModalData, setProcessModalData] = useState<FwProcessModalProps>(
    {}
  );

  const processBtnOnClick = (
    { batchTemplateId, process: { executions } }: BatchTemplate,
    { type /* , ...pcProps */ }
  ) => {
    if (
      // for case is table or mask
      [agenda, table, map, cards, timeline].includes(type) &&
      executions.length > 0
    ) {
      // sort step
      const sortedSteps = sortByStepRowColumnKey(executions);

      setProcessModalData({
        processID: batchTemplateId,
        processSteps: sortedSteps,
      });
    }
  };

  const style = mapDesignToStyle(design);

  return (
    <FwPageContentStoreProvider>
      {pcProcessBtns.length > 0 && (
        <FwSegment {...style} basic={type === section}>
          <FwPanel center>
            {pcProcessBtns.map((proc) => {
              const {
                process: { key, name, icon },
              } = proc;
              return (
                <FwButton
                  key={key}
                  primary
                  rounded
                  leftIcon={icon}
                  onClick={() => processBtnOnClick(proc, pc)}
                >
                  {t(`custom|${name}`)}
                </FwButton>
              );
            })}
          </FwPanel>
        </FwSegment>
      )}
      {type === section ? (
        // pc && visible ? (
        //<PageSegment basic {...segmentProps}>
        // copy data of section format JSON
        <Section {...props} section={pc} style={style} />
      ) : (
        // ) : (
        //   <Section {...props} section={pc} />
        // )
        <>
          {/*<PageSegment embedding={[iframe, map].includes(type)} {...segmentProps}>*/}
          {type === iframe ? (
            <FwIframe {...style} compact height={height} url={value} />
          ) : [table, agenda, cards, map, timeline].includes(type) ? (
            <TableMask
              documentID={props.documentID}
              mask={type !== table ? type : undefined}
              pageContentID={pageContentID}
              pcTableKey={tableKey}
              pcAdditionalData={additionalData}
              processes={tableProcessBtns}
              style={style}
              zoomed={zoomed}
            />
          ) : type === trackingByAddress ? (
            <TrackingByAddress style={style} />
          ) : type === history ? (
            <History documentID={props.documentID} style={style} />
          ) : type === image ? (
            <FwSegment {...style}>
              <FwImage src={value} />
            </FwSegment>
          ) : type === tiles ? (
            <TilesPanel routes={value} small={small} />
          ) : type === markdown ? (
            <FwSegment {...style}>
              <FwMarkdown>{value}</FwMarkdown>
            </FwSegment>
          ) : type === title ? (
            <FwSegment {...style}>
              <h3>{t(`custom|${value || ''}`)}</h3>
            </FwSegment>
          ) : (
            // default 'text'
            <FwSegment {...style}>
              <p>{t(`custom|${value || ''}`)}</p>
            </FwSegment>
          )}
        </>
      )}
      <FwProcessModal {...processModalData} />
    </FwPageContentStoreProvider>
  );
};

export default CustomPageContent;
