import _ from 'lodash';

import { PageContentStore } from 'components/base';

// get result from previous step or selected item
const getIdsByStepIndex = (store: PageContentStore, stepIdx: number) =>
  stepIdx <= 0
    ? store.selections
    : getProcessDataByPrevStep(store, stepIdx).result.ids;

const getProcessDataByStepIndex = (store: PageContentStore, stepIdx: number) =>
  store.processData[stepIdx];

const getProcessDataByPrevStep = (
  store: PageContentStore,
  currentStepIdx: number
) => getProcessDataByStepIndex(store, currentStepIdx - 1);

const getCloneStoreUpdatedProcessData = (
  store: PageContentStore,
  stepIndex: number,
  objUpdate: object
) => {
  let clonePCStore = _.cloneDeep(store);

  if (!clonePCStore.processData) {
    // init new object with objUpdate
    clonePCStore = {
      ...clonePCStore,
      processData: { [stepIndex]: objUpdate },
    };
  } else {
    // inject objUpdate
    clonePCStore.processData[stepIndex] = {
      ...clonePCStore.processData[stepIndex],
      ...objUpdate,
    };
  }

  return clonePCStore;
};

export {
  getIdsByStepIndex,
  getProcessDataByStepIndex,
  getProcessDataByPrevStep,
  getCloneStoreUpdatedProcessData,
};
