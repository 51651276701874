import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import aboutApi from 'api/about/aboutApi';
import { useApi } from 'api/useApi';

import About from './About';

const AboutContainer = () => {
  const [infoArgs] = useState([]);
  const [keyArgs, setKeyArgs] = useState([]);
  const [info, setInfo] = useState(null);
  const [generatedKey, setGeneratedKey] = useState(null);

  const { fetched: infoFetched, pending: infoPending } = useApi(
    aboutApi.getInfo,
    infoArgs
  );
  const { fetched: keyFetched, pending: keyPending } = useApi(
    aboutApi.getGeneratedKey,
    keyArgs
  );

  useEffect(() => {
    if (!infoPending) {
      setInfo(infoFetched.info);
    }
  }, [infoFetched, infoPending]);

  useEffect(() => {
    if (!keyPending) {
      setGeneratedKey(keyFetched.key);
    }
  }, [keyFetched, keyPending]);

  const onGenerateKey = () => {
    setKeyArgs([]);
  };

  return (
    <About
      generatedKey={generatedKey}
      info={info}
      loadingInfo={infoPending}
      loadingKey={keyPending}
      onGenerateKey={onGenerateKey}
    />
  );
};

export default AboutContainer;
