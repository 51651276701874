import { Route } from 'core/model';

// import Batch from '../batch/batchContainer';
import Doc from '../doc/docContainer';

const documentRoutes: Route[] = [
  // tODO {area}/{moduleKey}/{pageKey}/d/new/{templateID}
  // tODO {area}/{moduleKey}/{pageKey}/d/{documentID}
  {
    name: 'doc-with-step',
    path: '/:id([A-Za-z0-9-]{36})/:step',
    exact: true,
    component: Doc,
  },
  {
    path: '/:id([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'new',
    path: '/new/:templateId([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'new-with-step',
    path: '/new/:templateId([A-Za-z0-9-]{36})/:step',
    exact: true,
    component: Doc,
  },
  {
    path: '/:type/:id([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'public-with-step',
    path: 'public/:module/:id([A-Za-z0-9-]{36})/:step',
    exact: true,
    component: Doc,
  },
  {
    name: 'public-tracking-with-step',
    path: 'public/tracking/0/:id([A-Za-z0-9-]{36})/:step',
    exact: true,
    component: Doc,
  },
  // {
  //   path: '/batch',
  //   exact: true,
  //   component: Batch,
  // },
];

export default documentRoutes;
