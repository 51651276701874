import React, { useCallback } from 'react';

import { useFwSettings } from 'components/base';

import Preferences from './Preferences';

const PreferencesContainer = () => {
  const { dispatchAppPreference } = useFwSettings();

  const handleSetKey = useCallback((key) => {
    // todo set in user instead or maybe refetch user from token
    // dispatchAppPreference({ appPreferenceID: key });
  }, []);

  // apply new preferences
  const handleEntityChange = useCallback(
    (data) => {
      // todo wip#585 value(s) will be overwritten by string values
      dispatchAppPreference(data);
    },
    [dispatchAppPreference]
  );

  const props = {
    handleSetKey,
    handleEntityChange,
  };

  return <Preferences {...props} />;
};

export default PreferencesContainer;
