import { chakra, Tag, useColorModeValue } from '@chakra-ui/react';
import { bool, any, func, string, number, InferProps } from 'prop-types';
import React, { FC, forwardRef, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwContextMenu,
  FwParagraph,
  useFwCssStyle,
  useFwTheme,
} from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';

const EventLabel: FC<Props> = forwardRef(
  (
    {
      backgroundColor: backgroundColorProp,
      content,
      eventKey,
      processes,
      handleProcessActionClick,
      handleOpen,
      compact,
      empty,
      left,
      slots,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const {
      accent,
      _dark: { color: dColor },
      _light: { color: lColor },
    } = useFwTheme();
    const lblColor = useColorModeValue(dColor, lColor);
    const { backgroundColor: bg } = useFwCssStyle({
      backgroundColor: backgroundColorProp,
    });

    // define context menu items
    const contextItems = getContextMenuItems(
      t,
      () => handleOpen(eventKey),
      () => handleOpen(eventKey, true),
      processes,
      (processId) => handleProcessActionClick(processId, eventKey)
    );

    // defined styles
    const containerStyles = {
      left: `calc(${left} * 100%)`,
      top: `calc(50% - ${empty ? '8px' : '12px'})`,
      w: `calc(${slots || 0.17} * 100%)`,
    };

    const labelStyles = {
      cursor: 'pointer',
      minW: '5px',
      minH: empty ? '16px' : '24px',
      m: 0,
      border: '2px solid white',
      w: '100%',
      pl: '3px',
      pr: '3px',
      bg: bg || accent,
      color: lblColor,
    };

    const compactStyle = compact
      ? {
          px: 0,
        }
      : undefined;

    // render
    return (
      <chakra.div ref={ref} pos={'absolute'} {...containerStyles}>
        <FwContextMenu
          renderTag="div"
          childrenRenderTag="div"
          items={contextItems}
        >
          <Tag {...props} {...labelStyles} {...compactStyle}>
            <FwParagraph small truncated>
              {content}
            </FwParagraph>
          </Tag>
        </FwContextMenu>
      </chakra.div>
    );
  }
);

const propTypes = {
  backgroundColor: string,
  compact: bool,
  content: string,
  empty: bool,
  eventKey: string,
  left: number,
  processes: any,
  slots: number,
  handleProcessActionClick: func,
  handleOpen: func,
  onDoubleClick: func,
};

export type Props = InferProps<typeof propTypes>;

EventLabel.propTypes = propTypes;
EventLabel.displayName = 'EventLabel';

export default EventLabel;
