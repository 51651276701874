import _ from 'lodash';
import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import areaApi from 'api/area/areaApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, Area } from 'core/model';

const AreaLayout: FC<Props> = ({ area, handleSetKey }) => {
  const { t } = useTranslation();

  const castA: Area = Object.assign(new Area(), area);
  const inputs = getAdminInputs(castA, null, t);

  const entityDoc = getEntityDoc(area, inputs);

  return (
    <>
      {area && (
        <EntityInfo
          autosave
          api={areaApi}
          entityDoc={entityDoc}
          keyName={'key'}
          entityKey={area.key}
          type={'area'}
          title={'Area'}
          onSetKey={handleSetKey}
        />
      )}
    </>
  );
};

const propTypes = {
  area: any.isRequired,
  handleSetKey: func,
};

type Props = InferProps<typeof propTypes>;

AreaLayout.propTypes = propTypes;

export default withTranslation()(AreaLayout);
