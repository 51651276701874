import _ from 'lodash';

import { Option } from 'core/model';
import { ACTION, CONTENT_TYPE } from 'core/utils/constant';

import { applyScript } from '../../form/components/template/helpers/executeScript';

const { onChange, onLoad, search } = ACTION;
const { field } = CONTENT_TYPE;

const handlerSuggestion = (fields, suggValue) => {
  return (fields.suggestions = { result: suggValue });
};

const handlerOption = (fields, suggValue) => {
  return (fields.options = _.map(suggValue, function (option) {
    return new Option({
      key: option.key,
      value: option.value,
      text: option.text,
      items: option.items,
    });
  }));
};

const setResultInFields = (result, steps, modalInputs, handler) => {
  _.forOwn(result, (suggValue, suggKey) => {
    // inside steps
    _.forEach(steps, (s) => {
      _.forEach(s.contents, (content) => {
        _.forEach(
          _.filter(
            content.fields,
            (f) => !_.isEmpty(f.subInputs) || f.key === suggKey
          ),
          (f) => {
            if (!_.isEmpty(f.subInputs)) {
              // collection
              _.forEach(
                _.filter(f.subInputs, (si) => si.key === suggKey),
                (si) => {
                  handler(si, suggValue);
                }
              );
            } else if (f.key === suggKey) {
              // other input types
              handler(f, suggValue);
            }
          }
        );
      });
    });

    // inside modals
    _.forEach(
      _.filter(modalInputs, (f) => f.key === suggKey),
      (f) => {
        handler(f, suggValue);
      }
    );
  });
};

const setSuggestions = (
  dispatch: any,
  docData,
  currentVisibleElements,
  inputKey,
  { loading, result }: { loading?: any; result?: any }
) => {
  const steps = (currentVisibleElements || {}).steps;
  const modalInputs = (currentVisibleElements || {}).modalInputs;
  const loadingInputKey = { key: inputKey, loading: loading };

  // set suggestions in fields
  if (result?.suggestions) {
    setResultInFields(
      result.suggestions,
      steps,
      modalInputs,
      handlerSuggestion
    );
  }

  // set options in fields
  if (result?.options) {
    setResultInFields(result.options, steps, modalInputs, handlerOption);
  }

  // set data for autofill
  if (result?.data) {
    _.forOwn(result.data, (value, key) => (docData[key] = value));
  }

  // trigger render
  dispatch({
    docData: result && result.data ? docData : undefined,
    loadingInputKey,
    visibleElements: currentVisibleElements,
  });
};

const getCallback =
  (dispatch, docData, currentVisibleElements, inputKey) => (result) => {
    // apply suggestions
    setSuggestions(dispatch, docData, currentVisibleElements, inputKey, {
      loading: false,
      result: result || undefined,
    });
  };

class Suggestions {
  pendingFuncs = {};

  getSuggestions = (
    dispatch,
    doc,
    docData,
    updatedKeys,
    currentVisibleElements,
    indexItemChange?
  ) => {
    const { rules } = doc.template;
    const data = _.cloneDeep(docData || (doc.data ? JSON.parse(doc.data) : {}));

    if (!updatedKeys) {
      // on load
      const asyncRules = _.filter(
        rules,
        (r) =>
          r.script.action === search &&
          r.event.action === onLoad &&
          r.script.type === field
      );

      _.forEach(asyncRules, (r) => {
        applyScript([r], {
          doc,
          data,
          handler: getCallback(
            dispatch,
            docData,
            currentVisibleElements,
            r.event.key
          ),
        });
      });
    } else if (updatedKeys && updatedKeys.length === 1) {
      // on change, only search for suggestions when only 1 field changed
      _.forEach(updatedKeys, (updatedKey) => {
        // build updatedKey with index if has
        const updatedKeyWithIndex = `${updatedKey}${
          !_.isNil(indexItemChange) ? `|${indexItemChange}` : ''
        }`;

        // set reference before triggering search function
        const callCount = (this.pendingFuncs[updatedKeyWithIndex] || 0) + 1;
        this.pendingFuncs[updatedKeyWithIndex] = callCount;

        const asyncRules = _.filter(
          rules,
          (r) =>
            r.script.action === search &&
            r.event.action === onChange &&
            r.script.type === field &&
            r.event.key === updatedKey
        );

        if (asyncRules && asyncRules.length > 0) {
          setSuggestions(
            dispatch,
            docData,
            currentVisibleElements,
            updatedKeyWithIndex,
            { loading: true }
          );

          // delay function trigger
          setTimeout(() => {
            // only fire function if reference has not changed
            if (callCount === this.pendingFuncs[updatedKeyWithIndex]) {
              applyScript(asyncRules, {
                doc,
                data,
                handler: getCallback(
                  dispatch,
                  docData,
                  currentVisibleElements,
                  updatedKeyWithIndex
                ),
                indexItemChange,
              });
            }
          }, 750);
        }
      });
    }
  };
}

export default new Suggestions();
