import { Stack, PopoverArrow, useDisclosure } from '@chakra-ui/react';
import {
  arrayOf,
  bool,
  node,
  InferProps,
  instanceOf,
  oneOfType,
  string,
} from 'prop-types';
import React, { FC } from 'react';

import { FwPop, FwList, FwParagraph } from 'components/base';
import { FwCategoryProps, FwItemProps } from 'core/model';

const FwMenu: FC<Props> = ({
  trigger,
  arrow,
  inline,
  loading,
  messageWhenEmpty,
  items = [],
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return !trigger ? (
    <></>
  ) : (
    <FwPop
      inline={inline}
      open={isOpen && !loading}
      onOpen={onOpen}
      onClose={onClose}
    >
      <FwPop.Trigger>{trigger}</FwPop.Trigger>
      <FwPop.Content>
        {arrow && <PopoverArrow />}
        {items.length > 0 && (
          <Stack spacing={1}>
            <FwList small items={items} onChange={onClose} />
          </Stack>
        )}
        {items.length == 0 && messageWhenEmpty && (
          <FwParagraph small>{messageWhenEmpty}</FwParagraph>
        )}
      </FwPop.Content>
    </FwPop>
  );
};

const propTypes = {
  trigger: node,
  arrow: bool,
  inline: bool,
  loading: bool,
  messageWhenEmpty: string,
  items: arrayOf(
    oneOfType([instanceOf(FwCategoryProps), instanceOf(FwItemProps)])
  ),
};

export type Props = InferProps<typeof propTypes>;

FwMenu.propTypes = propTypes;

export default FwMenu;
