import { IMAGE_TYPES } from './mimeTypes';

export const SYSTEM_FIELDS = {
  area: 'AREA',
  formid: 'FORMID',
  number: 'NUMBER',
  formlayoutname: 'FORMLAYOUTNAME',
  formlayouttype: 'FORMLAYOUTTYPE',
  lastedit: 'LASTEDIT',
  status: 'STATUS',
  user: 'USER',
};

export const ACTION = {
  fill: 'FILL',
  focusIn: 'FOCUSIN',
  focusOut: 'FOCUSOUT',
  onChange: 'ONCHANGE',
  onLoad: 'ONLOAD',
  search: 'SEARCH',
  show: 'SHOW',
};

export const FIELD_TYPE = {
  // values use in backend, DB and frontend
  address: 'ADDRESS',
  autocomplete: 'AUTOCOMPLETE',
  barcode: 'BARCODE',
  checkbox: 'CHECKBOX',
  collection: 'COLLECTION',
  date: 'DATE',
  daterange: 'DATERANGE',
  datetime: 'DATETIME',
  decimal: 'DECIMAL',
  editselect: 'EDITSELECT',
  file: 'FILE',
  icon: 'ICON',
  idlink: 'IDLINK',
  label: 'LABEL',
  link: 'LINK',
  markdown: 'MARKDOWN',
  multidates: 'MULTIDATES',
  multiselect: 'MULTISELECT',
  number: 'NUMBER',
  password: 'PASSWORD',
  photo: 'PHOTO',
  qrcode: 'QRCODE',
  reference: 'REFERENCE',
  searcheditselect: 'SEARCHEDITSELECT',
  searchselect: 'SEARCHSELECT',
  select: 'SELECT',
  sign: 'SIGN',
  text: 'TEXT',
  textarea: 'TEXTAREA',
  time: 'TIME',
  timerange: 'TIMERANGE',
};

export const CONTENT_TYPE = {
  table: 'TABLE',
  iframe: 'IFRAME',
  trackingByAddress: 'TRACKINGBYADDRESS',
  title: 'TITLE',
  text: 'TEXT',
  agenda: 'AGENDA',
  history: 'HISTORY',
  templateHeader: 'TEMPLATEHEADER',
  image: 'IMAGE',
  map: 'MAP',
  cards: 'CARDS',
  timeline: 'TIMELINE',
  section: 'SECTION',
  popup: 'POPUP',
  field: 'FIELD',
  tiles: 'TILES',
  markdown: 'MARKDOWN',
};

export const SUB_CONTENT_TYPE = {
  category: 'CATEGORY',
};

export const OPERATOR = {
  contains: 'CONTAINS',
  notContains: 'NOTCONTAINS',
  equals: 'EQUALS',
  notEquals: 'NOTEQUALS',
  between: 'BETWEEN' /* value must be of type "from|to" */,
  startsWith: 'STARTSWITH',
  endsWith: 'ENDSWITH',
  in: 'IN',
};

export const FORM_LAYOUT_TYPE = {
  client: 'CLIENT',
  filter: 'FILTER',
  screen: 'SCREEN',
  print: 'PRINT',
  link: 'LINK',
};

const { png } = IMAGE_TYPES;

export const SIGN_EXPORT_FORMAT = {
  type: png,
  extension: Object.keys({ png })[0],
};

export const FIELD_KEY = {
  client: 'CLIENT.Code',
  longitude: 'Longitude',
  latitude: 'Latitude',
};

export const BUTTON_TYPE = {
  download: 'DOWNLOAD',
  edit: 'EDIT',
  filter: 'FILTER',
  link: 'LINK',
  popup: 'POPUP',
  preview: 'PREVIEW',
  print: 'PRINT',
  process: 'PROCESS',
  reset: 'RESET',
  save: 'SAVE',
  submit: 'SUBMIT',
};

export const MODAL_TEMPLATE = {
  confirm: 'Confirm',
  empty: 'Empty',
  warning: 'Warning',
};

export const ACTION_TYPE = {
  status: 'STATUS' /* todo change to SAVE/SUBMIT in admin-template */,
  print: 'PRINT',
  popup: 'POPUP',
  reset: 'RESET',
  link: 'LINK',
};

export const DEFAULT_OPTION = {
  key: '',
  value: '',
  text: '',
  items: null,
};

export const POPUP_TAB_NAME = {
  general: 'GENERAL',
  access: 'ACCESS',
  modal: 'MODAL',
};

export const ACCESS_RULE_TYPE = {
  role: 'ROLE',
  user: 'USER',
};

export const DOWNLOAD_TYPE = {
  csv: 'CSV',
  docx: 'DOCX',
  odt: 'ODT',
  pdf: 'PDF',
  xlsx: 'XLSX',
};

export const TRANSPORT_STATUS = {
  DONE: 'Done',
  IN_DISPATCHING: 'In Dispatching',
  TO_DISPATCH: 'To Dispatch',
  PICKUP_DELIVERY: 'Pickup Delivery',
};

export const ACCESS_RULE_PERMISSION = {
  create: 'CREATE',
  write: 'WRITE',
  read: 'READ',
};

export const ACCESS_RULE_ALLOW = {
  yes: 'YES',
  no: 'NO',
};

export const THEME = {
  light: 'LIGHT',
  dark: 'DARK',
};

export const COLOR = {
  red: 'red',
  orange: 'orange',
  yellow: 'yellow',
  olive: 'olive',
  green: 'green',
  teal: 'teal',
  blue: 'blue',
  violet: 'violet',
  purple: 'purple',
  pink: 'pink',
  brown: 'brown',
  grey: 'grey',
  black: 'black',
};

export const LOCALES = {
  en: 'EN',
  fr: 'FR',
};

export const defaultLocale = LOCALES.fr;

export const INVALID_DATE = 'INVALID_DATE';

export const DRAFT_STATUS = 'Draft';

export const LIMIT_CHAR = 255;

export const FILE_NAME_LIMIT = 50;

export const FILE_SIZE_LIMIT = 5; /* 5MB */

export const INVALID_FILE_TYPE = {
  invalidFileExtension: 'INVALID_FILE_EXTENSION',
  invalidSize: 'INVALID_SIZE',
  invalidImageExtension: 'INVALID_IMAGE_EXTENSION',
};

export const IMAGE_URL = {
  logoMedium: '/Storage/Image/logo.png',
  logoSmall: '/Storage/Image/logo-small.png',
  background: '/Storage/Image/background.png',
};

// mAP
export const DISPATCH_BASE_URL = 'http://localhost:8080/dispatch';
export const DISPATCH_PATHNAME = '/dispatch/map';
