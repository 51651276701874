import * as protectedDateFns from 'date-fns';
import * as locales from 'date-fns/locale';
import _ from 'lodash';

import { readSettings } from 'core/utils/storage';

const defaultLangCode = 'fr';
const defaultCountryCode = 'CA';

enum dateFormats {
  date = 'P' /* e.g. '2022-01-01' or '01/01/2022' */,
  dateLiteral = 'PP' /* e.g. '1er janvier 2022' */,
  datetime = 'P p' /* mix of localized date and time formats */,
  isoDate = 'yyyy-MM-dd',
  isoShort = "yyyy-MM-dd'T'HH:mm:00" /* escaping T for date-fns */,
  isoTime = 'HH:mm',
  monthYear = 'MMM yyyy',
  time = 'p' /* e.g. '10:00' or '10:00 AM' */,
  year = 'yyyy',
}

const getCulture = () => {
  const { language, countryCode } = readSettings() || {};
  const langCode = _.toLower(language) || defaultLangCode;
  const ctryCode = _.toUpper(countryCode) || defaultCountryCode;
  const culture = `${langCode}-${ctryCode}`;

  const dateFnsLocale =
    _.find(locales, (locale) => locale.code === culture) ||
    _.find(locales, (locale) => locale.code === langCode) ||
    _.find(
      locales,
      (locale) => locale.code === `${defaultLangCode}-${defaultCountryCode}`
    );

  return {
    langCode,
    countryCode: ctryCode,
    culture: dateFnsLocale?.code || langCode,
    dateFnsLocale: dateFnsLocale,
  };
};

const getLocale = () => {
  const { dateFnsLocale } = getCulture();
  return dateFnsLocale;
};

const getDateFns = () => {
  const locale = getLocale();
  protectedDateFns.setDefaultOptions({ locale });
  return protectedDateFns;
};

const isValidDate = (date: Date, dateFns = getDateFns()) => {
  return dateFns.isValid(date);
};

const getDateOrDefault = (date: Date) => {
  return date && isValidDate(date) ? date : new Date();
};

const isSameDay = (date1: Date, date2: Date, dateFns = getDateFns()) => {
  return dateFns.isSameDay(date1, date2);
};

const addMinutes = (date: Date, increment: number, dateFns = getDateFns()) => {
  return dateFns.addMinutes(date, increment);
};

const addDays = (date: Date, increment: number, dateFns = getDateFns()) => {
  return dateFns.addDays(date, increment);
};

const addWeeks = (date: Date, increment: number, dateFns = getDateFns()) => {
  return dateFns.addWeeks(date, increment);
};

const addMonths = (date: Date, increment: number, dateFns = getDateFns()) => {
  return dateFns.addMonths(date, increment);
};

const startOfDay = (date: Date, dateFns = getDateFns()) => {
  return dateFns.startOfDay(date);
};

const endOfDay = (date: Date, dateFns = getDateFns()) => {
  return dateFns.endOfDay(date);
};

const getDay = (date: Date, dateFns = getDateFns()) => {
  return { start: startOfDay(date, dateFns), end: endOfDay(date, dateFns) };
};

const startOfWeek = (date: Date, dateFns = getDateFns()) => {
  return dateFns.startOfWeek(date);
};

const endOfWeek = (date: Date, dateFns = getDateFns()) => {
  return dateFns.endOfWeek(date);
};

const getWeek = (date: Date, dateFns = getDateFns()) => {
  return { start: startOfWeek(date, dateFns), end: endOfWeek(date, dateFns) };
};

const startOfMonth = (date: Date, dateFns = getDateFns()) => {
  return dateFns.startOfMonth(date);
};

const endOfMonth = (date: Date, dateFns = getDateFns()) => {
  return dateFns.endOfMonth(date);
};

const getMonth = (date: Date, dateFns = getDateFns()) => {
  return { start: startOfMonth(date, dateFns), end: endOfMonth(date, dateFns) };
};

const getYear = (date: Date, dateFns = getDateFns()) => {
  return dateFns.getYear(date);
};

// fr-CA date format
const codeToFormat = {
  ['P']: 'yyyy-MM-dd',
  ['P p']: 'yyyy-MM-dd HH:mm',
};

const getDateFormat = (dateFormat: string) => {
  let newDateFormat: string;
  const code = getLocale().code;

  if (code == 'fr-CA') {
    newDateFormat = codeToFormat[dateFormat];
  }

  return newDateFormat ?? dateFormat;
};

const jsDateToString = (
  date: Date,
  dateFormat: `${dateFormats}`,
  dateFns = getDateFns()
) => {
  if (date && !isValidDate(date)) {
    console.error({ date, dateFormat, dateFns });
  }

  return date && isValidDate(date)
    ? dateFns.format(date, getDateFormat(dateFormat))
    : null;
};

const jsDateFromString = (
  dateString: string,
  dateFormat: `${dateFormats}`,
  dateFns = getDateFns()
): Date => {
  const date = dateString
    ? _.includes([dateFormats.isoShort, dateFormats.isoDate], dateFormat)
      ? dateFns.parseISO(dateString)
      : dateFns.parse(dateString, getDateFormat(dateFormat), new Date())
    : null;

  if (date && !isValidDate(date)) {
    console.error({ dateString, date, dateFormat, dateFns });
  }

  return date && isValidDate(date) ? date : null;
};

const changeDateStringFormat = (
  isoDateString: string,
  dateFormat: `${dateFormats}`,
  dateFns = getDateFns()
) => {
  const jsDate = jsDateFromString(isoDateString, dateFormats.isoShort, dateFns);

  return isValidDate(jsDate)
    ? jsDateToString(jsDate, dateFormat, dateFns)
    : null;
};

const dateMax = (d1: Date, d2: Date): Date => (d1 >= d2 ? d1 : d2);
const dateMin = (d1: Date, d2: Date): Date => (d1 <= d2 ? d1 : d2);

const initDateTime = (
  date: Date,
  hours: number,
  min?: number,
  sec?: number,
  ms?: number
): Date => {
  const cloneDate = new Date(date);
  cloneDate.setHours(hours, min, sec, ms);

  return cloneDate;
};

export {
  dateFormats,
  addDays,
  addMinutes,
  addMonths,
  addWeeks,
  changeDateStringFormat,
  getCulture,
  getDateOrDefault,
  getDay,
  getWeek,
  getMonth,
  getYear,
  isSameDay,
  jsDateFromString,
  jsDateToString,
  dateMax,
  dateMin,
  initDateTime,
};
