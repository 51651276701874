import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';

import pageContentApi from 'api/page-content/pageContentApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { PageContentManagement } from 'core/model';

import PageContent from './PageContent';

const PageContentContainer: FC<RouteChildrenProps> = ({ location, match }) => {
  const { pageId } = (location?.state as { pageId: string }) || {};
  const { pageContentId } = match.params as {
    pageContentId: string;
  };

  const argsPageContent = useRef(pageContentId ? [pageContentId] : []);

  const [pageContent, setPageContent] = useState<PageContentManagement>();

  const { fetched: fetchedPageContent, pending: pendingPageContent } = useApi(
    pageContentApi.getById,
    argsPageContent.current
  );

  // receive pageContent from backend and set in state
  useEffect(() => {
    if (!pendingPageContent && !pageContentId) {
      setPageContent(
        new PageContentManagement({ pageID: pageId, tableKey: '' })
      );
    } else {
      if (!pendingPageContent && fetchedPageContent) {
        setPageContent(fetchedPageContent.pageContent);
      }
    }
  }, [fetchedPageContent, pageContentId, pageId, pendingPageContent]);

  const handleSetID = (id: string) => {
    // set in state
    setPageContent({ ...pageContent, pageContentID: id });
  };

  // render
  return !pendingPageContent && pageContent ? (
    <PageContent pageContent={pageContent} handleSetKey={handleSetID} />
  ) : (
    <FwSpinner />
  );
};

export default withRouter(PageContentContainer);
