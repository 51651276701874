import _ from 'lodash';
import {
  arrayOf,
  any,
  bool,
  func,
  InferProps,
  node,
  oneOf,
  string,
} from 'prop-types';

import { MODAL_TYPES } from 'core/utils/constants';

import { ctor } from '../helper';

const fwModalPT = {
  additionalData: any,
  blur: bool,
  cancelName: string,
  content: node,
  data: any,
  filters: arrayOf(any),
  inputs: arrayOf(any),
  invalidInputKey: string,
  loadingInputKeys: arrayOf(string),
  message: string,
  name: string,
  noDimmerClick: bool,
  open: bool,
  submitName: string,
  type: oneOf(_.values(MODAL_TYPES)),
  warning: string,
  onCancel: func,
  onChangeData: func,
  onSubmit: func,
};

type FwModalPT = InferProps<typeof fwModalPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwModalProps extends FwModalPT {}
class FwModalProps implements FwModalPT {
  constructor(props?: FwModalPT) {
    ctor(this, props);
  }
}

export { fwModalPT, FwModalProps };
