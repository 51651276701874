import _ from 'lodash';
import { Views } from 'react-big-calendar';
import { TFunction } from 'react-i18next';

import {
  addDays,
  addMinutes,
  addMonths,
  addWeeks,
  dateFormats,
  getDay,
  getMonth,
  getWeek,
  jsDateFromString,
  jsDateToString,
} from 'core/utils/date';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';

const { DAY, WEEK, MONTH } = Views;
const supportedViews = { DAY, WEEK, MONTH };
const views = _.values(supportedViews);

// // startDay and endDay return the same date according to dateFormat... (no time tokens possible)
// const getStartDateDay = (date: Date) =>
//   getDateFormatted(getISOFromDate(getStartOfDay(date)));

// const getEndDateDay = (date: Date) =>
//   getDateFormatted(getISOFromDate(getEndOfDay(date)));

// const getStartDateWeek = (date: Date, isFrenchFormat: boolean) =>
//   getDateFormatted(
//     getISOFromDate(modifyDays(getStartOfISOWeek(date), isFrenchFormat ? 0 : -1))
//   );

// const getEndDateWeek = (date: Date, isFrenchFormat: boolean) =>
//   getDateFormatted(
//     getISOFromDate(modifyDays(getEndOfISOWeek(date), isFrenchFormat ? 0 : -1))
//   );

// const getStartDateMonth = (date: Date) =>
//   getDateFormatted(getISOFromDate(getStartOfMonth(date)));

// const getEndDateMonth = (date: Date) =>
//   getDateFormatted(getISOFromDate(getEndOfMonth(date)));

const timeStringToIsoTimeString = (timeString: string) => {
  return timeString?.length === 5 && _.indexOf(timeString, ':') === 2
    ? timeString
    : jsDateToString(
        jsDateFromString(timeString, dateFormats.time),
        dateFormats.isoTime
      );
};

const prevnext = (view: keyof Views, oldDate: Date, increment: 1 | -1) => {
  let newDate = oldDate;
  const mutator =
    view === MONTH
      ? addMonths
      : view === WEEK
      ? addWeeks
      : view === DAY
      ? addDays
      : null;

  if (mutator) {
    newDate = mutator(newDate, increment);
  }

  return newDate;
};

const getDateLabel = (view: keyof Views, date: Date) => {
  const format =
    view === MONTH || view === WEEK
      ? dateFormats.monthYear
      : view === DAY
      ? dateFormats.dateLiteral
      : null;

  return jsDateToString(date, format || dateFormats.date);
};

// get valid agenda view from string value
const formatView = (view: keyof Views) => {
  let formattedView = undefined;

  if (view && _.has(supportedViews, view)) {
    formattedView = Views[view];
  } else if (view && _.includes(_.values(supportedViews), view)) {
    formattedView = view;
  }

  return formattedView || WEEK;
};

// find start and end dates from specified calendar view and date
const computeDateRange = (date: Date, view: keyof Views = DAY) => {
  let startDate: Date, endDate: Date;
  const validView = formatView(view);

  const rangeGetter =
    validView === MONTH
      ? getMonth
      : validView === WEEK
      ? getWeek
      : validView === DAY
      ? getDay
      : null;

  if (rangeGetter) {
    const { start, end } = rangeGetter(date);
    startDate = start;
    endDate = end;
  }

  const rangeString = `${jsDateToString(
    startDate,
    dateFormats.isoDate
  )}|${jsDateToString(endDate, dateFormats.isoDate)}`;

  return { startDate, endDate, rangeString };
};

// // returns the provided date or if the date is unvalid returns current date and time
// const getDateOrDefault = (date: Date) => {
//   return isValidDate(date) ? date : new Date();
// };

// // update search by input criterion
// const setCriterion = (
//   criteria: any,
//   { key, value }: { key: string; value: string }
// ) => {
//   criteria.searchByInput[key] = value === '' ? undefined : value;
// };

// // add filter by date range to table criteria
// const initializeAgendaCriteria = (agenda: MaskStructure, criteria: any) => {
//   const {
//     view: { date, type },
//     document: { start },
//   } = agenda;
//   const { } = computeDateRange(
//     getDateOrDefault(getDateFromISO(date)),
//     type
//   );
//   setCriterion(criteria, { key: start[0], value: });

//   //if (end) {
//   //    setCriterion(criteria, { key: end, value: });
//   //  }
// };

// retrieve missing start or end from the other one and length
const getMissingDateTimeFromLength = (
  data: any,
  start: Date,
  end: Date,
  lengthFieldKey: string
) => {
  const missingStart = start ? false : true;

  let lengthValue: string = data[lengthFieldKey];

  if (_.includes(lengthValue, ':')) {
    const isoTimeString = timeStringToIsoTimeString(lengthValue);

    const timeArray =
      // array of hours and minutes
      _.split(isoTimeString, ':');
    lengthValue = `${
      _.toInteger(timeArray[0]) * 60 + _.toInteger(timeArray[1])
    }`;
  }

  return addMinutes(
    missingStart ? end : start,
    _.toInteger(`${missingStart ? '-' : ''}${lengthValue}`)
  );
};

// compute start or end of event
const getDateFromData = (data: any, fieldKeyArray: string[]) => {
  let datetime: Date = null;
  const values = _.compact(_.map(fieldKeyArray, (key) => data[key]));

  if (values && values.length === 1) {
    datetime = jsDateFromString(values[0], dateFormats.datetime);
  } else if (values && values.length === 2) {
    const dateString = values[0];
    const timeString = values[1];

    const isoTimeString = timeStringToIsoTimeString(timeString);

    datetime = jsDateFromString(
      `${jsDateToString(
        jsDateFromString(dateString, dateFormats.date),
        dateFormats.isoDate
      )}T${isoTimeString}`,
      dateFormats.isoShort
    );
  }

  return datetime;
};

// compute both start and end of event
const getEventStartAndEnd = (
  data: any,
  lastEdit: string,
  {
    eventStart,
    eventLength,
    eventEnd,
  }: { eventStart: string[]; eventLength: string; eventEnd: string[] }
) => {
  const eventStartAndEnd = {
    start: getDateFromData(data, eventStart),
    end: getDateFromData(data, eventEnd),
  };

  if (!eventStartAndEnd.start && eventStartAndEnd.end) {
    // start data is missing, compute it from end and length
    eventStartAndEnd.start = getMissingDateTimeFromLength(
      data,
      undefined,
      eventStartAndEnd.end,
      eventLength
    );
  } else if (eventStartAndEnd.start && !eventStartAndEnd.end) {
    // end data is missing, compute it from start and length
    eventStartAndEnd.end = getMissingDateTimeFromLength(
      data,
      eventStartAndEnd.start,
      undefined,
      eventLength
    );
  }

  // data is missing -> fallback to lastEdit
  if (!eventStartAndEnd.start || !eventStartAndEnd.end) {
    eventStartAndEnd.start = jsDateFromString(lastEdit, dateFormats.isoShort);
    eventStartAndEnd.end = jsDateFromString(lastEdit, dateFormats.isoShort);
  }

  return eventStartAndEnd;
};

// convert mask row to agenda event
const maskRowToEvent = (
  maskStructure: MaskStructure,
  maskRow: any,
  t: TFunction
) => {
  const {
    document: { start: eventStart, length, end: eventEnd, groupBy },
  } = maskStructure;
  const { key, lastEdit, data: rowData, color } = maskRow;

  // compute event start and end datetimes
  const { start, end } = getEventStartAndEnd(rowData, lastEdit, {
    eventStart,
    eventLength: length,
    eventEnd,
  });

  // define title
  const title = getMaskRowText(maskStructure, rowData, t);

  return {
    key,
    title,
    start,
    end,
    color,
    resourceId: groupBy ? rowData[groupBy] || '...' : undefined,
  };
};

// convert mask rows to agenda events
const maskRowsToEvents = (
  maskStructure: MaskStructure,
  maskRows: any[],
  t: TFunction
) => {
  const events = _.map(maskRows, (mr) => maskRowToEvent(maskStructure, mr, t));
  const resources = _.map(
    _.compact(_.uniq(_.map(events, (event) => event.resourceId))),
    (r) => ({ resourceId: r })
  );

  return {
    events,
    resources: resources && resources.length > 0 ? resources : undefined,
  };
};

const messagesFrench = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Calendrier',
  date: 'Date',
  time: 'Heure',
  event: 'Événement',
  showMore: (total: number) => `+ ${total} événement(s) supplémentaire(s)`,
};

export {
  messagesFrench,
  supportedViews,
  views,
  Views,
  formatView,
  getDateLabel,
  getEventStartAndEnd,
  computeDateRange,
  maskRowsToEvents,
  prevnext,
  // getDateOrDefault,
  // initializeAgendaCriteria,
};
