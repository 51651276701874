import {
  Box,
  Center,
  chakra,
  Grid,
  GridItem,
  Skeleton,
  Table,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr,
  RequiredIndicator,
  FormControl,
} from '@chakra-ui/react';
import _ from 'lodash';
import {
  any,
  arrayOf,
  bool,
  InferProps,
  object,
  string,
  func,
  number,
  shape,
} from 'prop-types';
import React, { FC, useMemo, useEffect, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  useAsyncDebounce,
} from 'react-table';

import {
  FwIcon,
  FwInput,
  FwLink,
  FwPaginator,
  FwParagraph,
  FwTitle,
  useFwTheme,
} from 'components/base';
import { mapColumnToInput } from 'core/mapper';
import { FIELD_TYPE } from 'core/utils/constant';
import { collectionItemIsEmptyPred } from 'core/utils/utilsModal';

import { Row, RowProps } from './components';

const { text } = FIELD_TYPE;

export const COLUMN_DELETE_ID = 'delete';
const WAIT_INTERVAL = 700;

const CommonPropTypes = {
  totalRow: number,
  loading: bool,
  hidePagination: bool,
};
type CommonProps = InferProps<typeof CommonPropTypes>;

// styled th (Chakra Th is upper casing text)
const Th = chakra('th', { baseStyle: { px: '5px' } });

// table Header component
const FwHeader: FC<FwHeaderProps & CommonProps> = ({
  hideGlobalFilter,
  hidePagination,
  currentPage,
  totalPage,
  totalRow,
  loading,
  cache,
  handleSearchAllChange,
}) => {
  const { t } = useTranslation();

  const onChange = useAsyncDebounce((value) => {
    // trigger request after WAIT_INTERVAL time
    handleSearchAllChange(value);
  }, WAIT_INTERVAL);

  return (
    <Grid alignItems="center" templateColumns="repeat(3, 1fr)" mb={2}>
      <GridItem>
        {!hideGlobalFilter && (
          <Box>
            <FwInput
              search
              placeholder={t('common|Search...')}
              defaultValue={(cache && cache.search) || undefined}
              rightIcon={'RiSearchLine'}
              loading={loading}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value);
              }}
              type={text}
            />
          </Box>
        )}
      </GridItem>
      <GridItem textAlign="center">
        {!hidePagination && (
          <Skeleton isLoaded={!loading}>
            <FwParagraph small>
              {t('Page')} <strong>{currentPage}</strong> {t('out of')}{' '}
              <strong>{totalPage}</strong>
            </FwParagraph>
          </Skeleton>
        )}
      </GridItem>
      <GridItem textAlign="right">
        {!hidePagination && (
          <Skeleton isLoaded={!loading}>
            <FwParagraph small>
              {t('Total of')} <strong>{totalRow}</strong> {t('form(s)')}
            </FwParagraph>
          </Skeleton>
        )}
      </GridItem>
    </Grid>
  );
};

const FwHeaderPropTypes = {
  cache: any,
  currentPage: number,
  handleSearchAllChange: func,
  hideGlobalFilter: bool,
  totalPage: number,
};
type FwHeaderProps = InferProps<typeof FwHeaderPropTypes>;
FwHeader.propTypes = FwHeaderPropTypes;

// table Footer component
const FwFooter: FC<FwFooterProps & CommonProps> = ({
  allowAddRow,
  disableAddRow,
  hidePagination,
  totalRow,
  currentPage,
  rowPerPage,
  loading,
  handleAddRow,
  handlePageChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {allowAddRow && (
        <Grid>
          <GridItem>
            <FwParagraph small>
              <FwLink
                leftIcon="RiAddCircleLine"
                disabled={disableAddRow}
                onClick={!disableAddRow ? handleAddRow : undefined}
              >
                {t('common|Add')}
              </FwLink>
            </FwParagraph>
          </GridItem>
        </Grid>
      )}
      {!hidePagination && (
        <Skeleton isLoaded={!loading}>
          <Grid alignItems="center" templateColumns="repeat(3, 1fr)">
            <GridItem textAlign="left">
              <FwParagraph small>
                {t('Total of')} <strong>{totalRow}</strong> {t('form(s)')}
              </FwParagraph>
            </GridItem>
            <GridItem>
              <Center>
                <FwPaginator
                  current={currentPage}
                  pageSize={rowPerPage}
                  total={totalRow}
                  pageNeighbours={2}
                  paginationProps={{ display: 'flex' }}
                  onChange={handlePageChange}
                />
              </Center>
            </GridItem>
            <GridItem textAlign="right"></GridItem>
          </Grid>
        </Skeleton>
      )}
    </>
  );
};

const FwFooterPropTypes = {
  allowAddRow: bool,
  disableAddRow: bool,
  currentPage: number,
  handleAddRow: func,
  handlePageChange: func,
  rowPerPage: number,
};
type FwFooterProps = InferProps<typeof FwFooterPropTypes>;
FwFooter.propTypes = FwFooterPropTypes;

// fwTable component
const FwTable: FC<FwTableProps> = ({
  title,
  columns,
  data,
  collapse,
  compact,
  currentPage,
  totalPage,
  totalRow,
  rowPerPage,
  cache,
  activeItem,
  activeColumn,
  direction,
  loading,
  processes,
  inputs,
  isInputReadOnly,
  isInputDisabled,
  isInvalid,
  invalidKey,
  invalidRow,
  height,
  loadingKeys,
  allowAddRow = false,
  allowDeleteRow = false,
  hideFilters = false,
  hideGlobalFilter = false,
  hidePagination = false,
  allowContextMenu = true,
  allowDoubleClick = true,
  allowViewed = true,
  renderDataAsInput = false,
  selectable = false,
  sortable = false,
  showEmptyRowsMessage = true,
  showFooter = false,
  wrap = true,
  handleAddRow,
  handleDataInputBlur,
  handleDataInputChange,
  handleDeleteRow,
  handleSearchAllChange,
  handleSearchFieldChange,
  handlePageChange,
  handleProcessActionClick,
  handleOpen,
  handleSort,
  handleRowClick,
}) => {
  const { t } = useTranslation();
  const {
    bg,
    borderColor,
    _disabled: {
      bg: cellBg,
      _hover: { bg: cellHoverBg },
    },
  } = useFwTheme();

  const sortByMemo = useMemo(
    () => [{ id: activeColumn, desc: direction === 'descending' }],
    [activeColumn, direction]
  );

  const tableHeaderProps =
    height || compact
      ? ({
          background: bg,
          position: 'sticky',
          top: '-3px',
          zIndex: 5,
        } as const)
      : undefined;

  const filterOnChange = useAsyncDebounce((id, value) => {
    // trigger request after WAIT_INTERVAL time
    handleSearchFieldChange({
      key: id,
      value,
    });
  }, WAIT_INTERVAL);

  // config default column filter in useTable
  const DefaultColumnFilter = (tableInstance) => {
    const {
      column: { id },
    } = tableInstance;
    const { filters } = cache || {};
    const inputFilter = inputs && _.find(inputs, { key: id });

    return inputFilter ? (
      <FwInput
        search
        {...mapColumnToInput(inputFilter)}
        // override props
        defaultValue={(filters && filters[id]) || undefined}
        value={undefined}
        onChange={(_e, { value }) => {
          filterOnChange(id, value);
        }}
      />
    ) : (
      <></>
    );
  };

  const {
    footerGroups,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    // table state
    state: { sortBy },
    rows,
  } = useTable(
    {
      initialState: {
        sortBy: sortByMemo,
      },
      columns,
      data,
      defaultColumn: {
        Filter: DefaultColumnFilter,
      },
      // filter configs
      manualFilters: true,
      // sort configs
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    (hooks) => {
      // add column for delete button
      allowDeleteRow &&
        columns.length > 0 &&
        hooks.visibleColumns.push((cols) => [
          ...cols,
          {
            id: COLUMN_DELETE_ID,
          },
        ]);
    }
  );

  const sortByRef = useRef(sortBy);

  useEffect(() => {
    if (
      sortable &&
      sortBy.length > 0 &&
      !_.isEqual(sortByRef.current, sortBy)
    ) {
      handleSort(sortBy[0].id);
      sortByRef.current = sortBy;
    }
  }, [sortable, sortBy, handleSort]);

  // todo #585 refactor styling
  const invalidTableContainerStyle = {
    // this style is same as the style invalid in FormControl
    border: '1px',
    borderColor: 'red.500',
    borderRadius: 'md',
    boxShadow: '0 0 0 1px var(--chakra-colors-red-500)',
  };

  const heightPixel = `${height}${'px'}`;
  const compactHeight = compact
    ? 0 < height && height < 400
      ? heightPixel
      : '400px'
    : heightPixel;

  // todo #585 refactor styling?
  const tableContainerStyle = {
    maxW: '100%',
    overflow: 'auto',
    mb: 2,
    maxH: height ? compactHeight : compact ? '400px' : undefined,
    minH: collapse ? '0px' : height ? compactHeight : '355px',
    ...(isInvalid ? invalidTableContainerStyle : {}),
  };

  // todo #585 refactor styling?
  const tableStyle = {
    size: 'sm',
    sx: {
      th: {
        bg: cellBg,
        borderLeft: 'var(--chakra-borders-1px)',
        borderLeftColor: borderColor,
        _hover: sortable ? { bg: cellHoverBg } : undefined,
        _first: {
          borderLeft: 'none',
          borderTopLeftRadius: '5px',
        },
        _last: {
          borderTopRightRadius: '5px',
        },
      },
      td: {
        borderLeft: 'var(--chakra-borders-1px)',
        borderLeftColor: borderColor,
      },
    },
  };

  return (
    <>
      <FwTitle>{title}</FwTitle>
      {!_.isEmpty(headerGroups) && (
        <FwHeader
          hideGlobalFilter={hideGlobalFilter}
          hidePagination={hidePagination}
          currentPage={currentPage}
          totalPage={totalPage}
          totalRow={totalRow}
          loading={loading}
          cache={cache}
          handleSearchAllChange={handleSearchAllChange}
        />
      )}
      {/* table */}
      <Box {...tableContainerStyle}>
        <Table {...getTableProps()} {...tableStyle}>
          <Thead {...tableHeaderProps}>
            {_.isEmpty(headerGroups) && (
              <Tr>
                <Th>
                  <Skeleton isLoaded={!loading}>
                    {t('No fields found, please add fields to see the results')}
                  </Skeleton>
                </Th>
              </Tr>
            )}
            {_.map(headerGroups, (hg) => (
              <Tr {...hg.getHeaderGroupProps()}>
                {_.map(
                  hg.headers,
                  ({
                    id,
                    getHeaderProps,
                    getSortByToggleProps,
                    isSorted,
                    isSortedDesc,
                    render,
                    // width,
                    canFilter,
                  }) => {
                    const headerRender = render('Header');
                    const showRequiredIndicator = (
                      inputs && _.find(inputs, { key: id })
                    )?.required;

                    return (
                      <Th
                        {...getHeaderProps(getSortByToggleProps())}
                        style={{
                          cursor: sortable ? 'pointer' : 'default',
                          fontWeight: 'unset',
                        }}
                        title={
                          // remove title from getSortByToggleProps()
                          undefined
                        }
                        // width={width}
                      >
                        <FwParagraph small truncated>
                          <FormControl
                            // render span to avoid warning on prop
                            as={chakra.span}
                            isRequired={showRequiredIndicator}
                          >
                            {/* collections have string typeof and tables have object typeof */}
                            <b>
                              {typeof headerRender === 'string'
                                ? t(`custom|${headerRender}`)
                                : headerRender}
                            </b>
                            {showRequiredIndicator && <RequiredIndicator />}
                          </FormControl>
                          {sortable && isSorted && (
                            <FwIcon
                              inline
                              name={
                                isSortedDesc
                                  ? 'RiArrowDownSFill'
                                  : 'RiArrowUpSFill'
                              }
                            />
                          )}
                        </FwParagraph>
                        {/* avoid trigger sort when clicking on filter input */}
                        <div onClick={(e) => e.stopPropagation()}>
                          {!hideFilters && canFilter ? render('Filter') : null}
                        </div>
                      </Th>
                    );
                  }
                )}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {loading && (
              <Tr>
                {_.map(columns, (_, idx) => (
                  <Td key={idx}>
                    <Skeleton height="25px" />
                  </Td>
                ))}
              </Tr>
            )}
            {!loading &&
              _.isEmpty(rows) &&
              !_.isEmpty(headerGroups) &&
              showEmptyRowsMessage && (
                <Tr>
                  <Td colSpan={columns.length}>{t('No records found')}</Td>
                </Tr>
              )}
            {!loading &&
              _.map(rows, (row, idx) => {
                prepareRow(row);

                return (
                  <Row
                    key={idx}
                    row={row}
                    renderDataAsInput={renderDataAsInput}
                    inputs={inputs}
                    isInputReadOnly={isInputReadOnly}
                    isInputDisabled={isInputDisabled}
                    invalidKey={invalidRow === idx ? invalidKey : undefined}
                    loadingKeys={loadingKeys}
                    selectable={selectable}
                    activeItem={activeItem}
                    processes={processes}
                    wrap={wrap}
                    allowContextMenu={allowContextMenu}
                    allowDoubleClick={allowDoubleClick}
                    allowViewed={allowViewed}
                    handleProcessActionClick={handleProcessActionClick}
                    handleOpen={handleOpen}
                    handleRowClick={handleRowClick}
                    handleDataInputBlur={handleDataInputBlur}
                    handleDataInputChange={handleDataInputChange}
                    handleDeleteRow={handleDeleteRow}
                  />
                );
              })}
          </Tbody>
          {showFooter && (
            <Tfoot>
              {_.map(footerGroups, (fg) => (
                <Tr {...fg.getFooterGroupProps()}>
                  {_.map(fg.headers, ({ getFooterProps, render }) => {
                    const headerRender = render('Footer');
                    return <Th {...getFooterProps()}>{headerRender}</Th>;
                  })}
                </Tr>
              ))}
            </Tfoot>
          )}
        </Table>
      </Box>
      {/* footer */}
      {!_.isEmpty(headerGroups) && (
        <FwFooter
          allowAddRow={allowAddRow}
          disableAddRow={
            allowAddRow
              ? isInputReadOnly ||
                isInputDisabled ||
                data.some(collectionItemIsEmptyPred)
              : undefined
          }
          hidePagination={hidePagination}
          totalRow={totalRow}
          currentPage={currentPage}
          rowPerPage={rowPerPage}
          loading={loading}
          handlePageChange={handlePageChange}
          handleAddRow={handleAddRow}
        />
      )}
    </>
  );
};

const FwTableColumnPropTypes = {
  type: string,
  options: arrayOf(
    shape({
      key: string,
      value: string,
      text: string,
      items: arrayOf(
        shape({
          key: string,
          value: string,
          text: string,
        })
      ),
    })
  ),
};

export type FwTableColumnProps = InferProps<typeof FwTableColumnPropTypes> &
  Column;

const FwTablePropTypes = {
  activeColumn: string,
  allowDeleteRow: bool,
  collapse: bool,
  compact: bool,
  data: arrayOf(object),
  direction: string,
  handlePageChange: func,
  handleSearchFieldChange: func,
  handleSort: func,
  height: number,
  hideFilters: bool,
  isInvalid: bool,
  invalidRow: number,
  title: string,
  sortable: bool,
  showEmptyRowsMessage: bool,
  showFooter: bool,
  wrap: bool,
};

interface FwPartialTableProps extends InferProps<typeof FwTablePropTypes> {
  columns?: Array<FwTableColumnProps>;
}

export type FwTableProps = FwPartialTableProps &
  FwHeaderProps &
  FwFooterProps &
  CommonProps &
  RowProps;

FwTable.propTypes = FwTablePropTypes;

export default FwTable;
