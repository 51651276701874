import _ from 'lodash';

import { ACTION, CONTENT_TYPE, SUB_CONTENT_TYPE } from 'core/utils/constant';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';

import { applyScript } from '../../form/components/template/helpers/executeScript';

const { onChange, show } = ACTION;
const { category } = SUB_CONTENT_TYPE;
const { field, section } = CONTENT_TYPE;

const ruleHandler = (updatedElements) => (rule: any, docData, value, key) => {
  const { fieldKey = undefined, catKey = undefined } =
    rule.script.type === category ? utils.splitKeyAutoHideForCategory(key) : {};

  const old = updatedElements[rule.script.type] || [];
  const newArray = [{ key: catKey || key, value }];
  const union = _.union(fieldKey ? old[fieldKey] : old, newArray);

  updatedElements[rule.script.type] = fieldKey
    ? { ...old, [fieldKey]: union }
    : union;
};

const setVisible = (array, updatedElementsByType) => {
  _.forEach(
    _.filter(array, (i) => _.some(updatedElementsByType, { key: i.key })),
    (item) => {
      const { value } = _.find(updatedElementsByType, { key: item.key });
      item.visible = value;
    }
  );

  return sortByStepRowColumnKey(array);
};

const setSubArrayVisible = (
  contentType,
  contentProperty,
  array,
  updatedElements
) => {
  _.forEach(array, (item) => {
    item[contentProperty] = setVisible(
      item[contentProperty],
      updatedElements[contentType]
    );
  });

  return array;
};

const updateCategories = (inputs, updatedCats) => {
  _.forEach(inputs, (i) => {
    if (
      updatedCats &&
      updatedCats[i.key] &&
      i.dropdown &&
      _.some(i.dropdown.options, (o) => o.items)
    ) {
      i.dropdown = {
        ...i.dropdown,
        options: setVisible(
          _.cloneDeep(i.dropdown.options),
          updatedCats[i.key]
        ),
      };
    }
  });
};

const getVisibleElements = (
  doc,
  docData,
  updatedKeys?,
  currentVisibleElements?,
  indexItemChange?
) => {
  const { rules, steps, modals, clickables } = doc.template;
  const updatedSteps = (currentVisibleElements || {}).steps || steps;
  const updatedModalInputs =
    (currentVisibleElements || {}).modalInputs ||
    _.union(
      utils.getFlatSubArray(modals, [(m) => m.inputs], true),
      utils.getFlatSubArray(
        clickables,
        [(c) => (c.modal ? c.modal.inputs : undefined)],
        true
      )
    );

  const updatedElements = {};

  const autoShowHideRules = rules.filter(
    (r) =>
      r.script.action === show &&
      (updatedKeys ? r.event.action === onChange : true) &&
      (updatedKeys ? _.includes(updatedKeys, r.event.key) : true)
  );

  applyScript(autoShowHideRules, {
    doc,
    data: docData,
    handler: ruleHandler(updatedElements),
    indexItemChange,
  });

  // update sections
  setSubArrayVisible(section, 'contents', updatedSteps, updatedElements);

  _.forEach(updatedSteps, (vs) => {
    // update fields
    setSubArrayVisible(field, 'fields', vs.contents, updatedElements);

    _.forEach(vs.contents, (content) => {
      // update dropdown categories of collection sub-fields
      _.forEach(
        content.fields.filter((f) => f.subInputs.length > 0),
        (f) => {
          // clone to avoid modify props doesn't trigger re-render
          const subInputs = _.cloneDeep(f.subInputs);

          // update collection sub-fields and their categories
          setVisible(subInputs, updatedElements[field]);
          updateCategories(subInputs, updatedElements[category]);

          // assign back
          f.subInputs = subInputs;
        }
      );

      // update dropdown categories
      updateCategories(content.fields, updatedElements[category]);
    });
  });

  // update modal fields and categories
  setVisible(updatedModalInputs, updatedElements[field]);
  updateCategories(updatedModalInputs, updatedElements[category]);

  return currentVisibleElements || { steps, modalInputs: updatedModalInputs };
};

export default getVisibleElements;
