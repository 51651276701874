import _ from 'lodash';
import {
  arrayOf,
  bool,
  func,
  InferProps,
  instanceOf,
  number,
  oneOfType,
  string,
} from 'prop-types';
import React, { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FwButton } from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import {
  dateFormats,
  getCulture,
  getDay,
  getYear,
  jsDateFromString,
  jsDateToString,
} from 'core/utils/date';

import '../FwInput.Datetime.styles.css';
import { getIsoDateFormat } from '../FwInput.Datetime.helpers';

const { date, datetime, time, timerange } = FIELD_TYPE;

const day = 'day',
  month = 'month',
  year = 'year';

const defaultPickerTypeIdx = 1;
const pickerTypes = {
  [defaultPickerTypeIdx]: day,
  [defaultPickerTypeIdx + 1]: month,
  [defaultPickerTypeIdx + 2]: year,
};
const stringFormats = {
  [defaultPickerTypeIdx]: dateFormats.monthYear,
  [defaultPickerTypeIdx + 1]: dateFormats.year,
  [defaultPickerTypeIdx + 2]: dateFormats.year,
};

const Picker: FC<Props> = ({
  multiple,
  pattern,
  range,
  step,
  type,
  value,
  onChange,
}) => {
  // manage calendat type (day picker, month picker, year picker)
  const [pickerIdx, setPickerIdx] = useState(defaultPickerTypeIdx);

  const onPickerTypeClick = () => {
    // loop through picker types
    setPickerIdx(pickerIdx === _.keys(pickerTypes).length ? 1 : pickerIdx + 1);
  };

  // lib sends date array for ranges and single dates otherwise
  const handleSelection = (selection: Date | Date[]) => {
    // unloop back to first picker type
    if (pickerIdx === defaultPickerTypeIdx) {
      onChange(selection);
    } else {
      setPickerIdx(pickerIdx - 1);
    }
  };

  // restrict selectable dates and times via pattern prop
  const applyPattern = (filterTime: boolean) => (calendarDate: Date) => {
    // turn pattern into range of js dates
    const range = _.map((pattern || '').split('|'), (stringValue) =>
      jsDateFromString(stringValue, getIsoDateFormat(type))
    );

    // check if calendar date is inside range (extended to end of day in date mode)
    const dateIsSelectable =
      calendarDate >=
        (!filterTime && type === datetime
          ? getDay(range[0]).start
          : range[0]) &&
      calendarDate <= (type === date ? getDay(range[1]).end : range[1]);

    return dateIsSelectable;
  };

  const canApplyPattern =
    pattern &&
    // do not block month and year selections outside pattern range
    pickerIdx === defaultPickerTypeIdx;

  // set culture for UI via date-fns
  const { culture, dateFnsLocale } = getCulture();
  registerLocale(culture, dateFnsLocale);

  // create custom toolbar above calendar (when applies)
  const createToolbar = ({
    date,
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
  }) => (
    <div>
      <FwButton
        small
        leftIcon={'RiArrowLeftSLine'}
        onClick={pickerTypes[pickerIdx] === day ? decreaseMonth : decreaseYear}
      />
      <FwButton small onClick={onPickerTypeClick}>
        {pickerTypes[pickerIdx] === year
          ? `${getYear(date) - (getYear(date) % 12) + 1} - ${
              getYear(date) - (getYear(date) % 12) + 12
            }`
          : jsDateToString(date, stringFormats[pickerIdx])}
      </FwButton>
      <FwButton
        small
        leftIcon={'RiArrowRightSLine'}
        onClick={pickerTypes[pickerIdx] === day ? increaseMonth : increaseYear}
      />
    </div>
  );

  return (
    <DatePicker
      inline
      allowSameDay
      disabledKeyboardNavigation
      filterDate={canApplyPattern ? applyPattern(false) : undefined}
      filterTime={canApplyPattern ? applyPattern(true) : undefined}
      highlightDates={multiple ? value : undefined}
      locale={culture}
      selected={_.isArray(value) ? _.last(value) : value}
      selectsRange={range}
      showMonthYearPicker={pickerTypes[pickerIdx] === month}
      showYearPicker={pickerTypes[pickerIdx] === year}
      showTimeSelect={_.includes([time, timerange, datetime], type)}
      showTimeSelectOnly={type === time}
      startDate={range ? value[0] : undefined}
      endDate={range ? value[1] : undefined}
      timeIntervals={step || 15}
      onChange={handleSelection}
      renderCustomHeader={createToolbar}
    />
  );
};

const propTypes = {
  multiple: bool,
  pattern: string,
  range: bool,
  step: number,
  type: string,
  value: oneOfType([instanceOf(Date), arrayOf(instanceOf(Date))]),
  onChange: func,
};

export type Props = InferProps<typeof propTypes>;

Picker.propTypes = propTypes;

export default Picker;
