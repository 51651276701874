import { Box, chakra, Divider, Kbd, Stack } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, InferProps } from 'prop-types';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  TileLayer,
  LayerGroup,
  Marker,
  Popup,
  Tooltip,
  GeoJSON,
} from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'lrm-graphhopper';

import { FwLink } from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';

import { mapViewTypes } from '../FwMask.structures';
import FwHelpBox from './FwHelpBox';
import {
  DblClickComponent,
  RightClickComponent,
  AltZoomComponent,
  maskRowToMarker,
  validateViewport,
  getLabelledMarkerIcon,
  maskRowToGeoJson,
  onEachFeature,
  getGeoJsonFeatureCollection,
} from './FwMask.Map.helpers';

const DEFAULT_VIEWPORT = {
  center: [28, 4],
  zoom: 2,
};

const ChakraMapContainer = chakra(MapContainer, {
  baseStyle: {
    h: 'full',
    w: 'full',
  },
});

const tileLayerProps = {
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
};

const FwMap: FC<FwMaskCommonProps> = ({
  maskStructure,
  maskRows,
  processes,
  handleProcessActionClick,
  handleOpen,
  zoomed,
}) => {
  const { view } = maskStructure || {};
  const { t } = useTranslation();

  // rendered geoJson count ref
  const geoJsonCountRef = useRef(0);
  const maskRowsRef = useRef({});

  const [geoJsons, setGeoJsons] = useState({});
  const [markers, setMarkers] = useState([]);
  const [viewport /* , setViewport */] = useState(
    validateViewport(view) ? view : DEFAULT_VIEWPORT
  );

  // const onViewportChanged = (newViewport) => {
  //   setViewport(newViewport);
  // };

  useEffect(() => {
    if (
      maskStructure &&
      maskRows &&
      !_.isEqual(maskRowsRef.current, maskRows)
    ) {
      setMarkers(
        _.compact(
          _.map(maskRows, (mr) => maskRowToMarker(maskStructure, mr, t))
        )
      );

      const features = _.map(maskRows, (mr) => {
        return maskRowToGeoJson(maskStructure, mr);
      });

      setGeoJsons(getGeoJsonFeatureCollection(features));
      geoJsonCountRef.current += 1;
      maskRowsRef.current = maskRows;
    }
  }, [maskStructure, maskRows, t]);

  // save in the state wether the map is maximized or not
  const [zoomState, setZoomState] = useState(zoomed);
  useEffect(() => {
    setZoomState(zoomed);
  }, [zoomed]);

  const mapProps: MapProps = {
    scrollWheelZoom: zoomed !== undefined ? zoomed : false,
    dragging: true /*false when in mobile*/,
    center: viewport.center,
    zoom: viewport.zoom,
    // onViewportChanged,
  };

  const geoJsonProp = { onEachFeature };

  return (
    <Box h="100%" mt="-6px" mx="-6px">
      <ChakraMapContainer zIndex="base" {...mapProps}>
        {maskStructure?.view?.geo && (
          <GeoJSON data={maskStructure.view.geo} {...geoJsonProp} />
        )}
        <GeoJSON
          key={`geoJson-${geoJsonCountRef.current}`}
          data={geoJsons}
          {...geoJsonProp}
        />

        <DblClickComponent />
        <RightClickComponent />
        {!zoomState && <AltZoomComponent />}
        <TileLayer {...tileLayerProps} />
        <LayerGroup>
          {_.map(markers, (m, i) => {
            const [key, lat, lng, tooltip, color, label] = m;
            const markerProps = {
              position: [lng, lat],
              icon: getLabelledMarkerIcon(color, label),
            };

            // define context menu items
            const contextItems = getContextMenuItems(
              t,
              () => handleOpen(key),
              () => handleOpen(key, true),
              processes,
              (processId) => handleProcessActionClick(processId, key)
            );

            return (
              <Marker key={`marker-${i}`} {...markerProps}>
                <Popup>
                  <Stack>
                    {contextItems.map(
                      ({ itemKey, text, ...linkProps }, index) => (
                        <Fragment key={itemKey}>
                          {index > 0 && <Divider />}
                          <FwLink {...linkProps}>{text}</FwLink>
                        </Fragment>
                      )
                    )}
                  </Stack>
                </Popup>

                <Tooltip>
                  {_.map(tooltip.split('\n'), (t, k) => (
                    <div key={k}>{t}</div>
                  ))}
                </Tooltip>
              </Marker>
            );
          })}
        </LayerGroup>
        <FwHelpBox heading={t('How to use the map ?')}>
          {[
            [
              t('Zoom with scroll wheel : '),
              !zoomed ? <Kbd key="00">Alt</Kbd> : null,
              !zoomed ? '+' : null,
              <Kbd key="01">{t('Scroll wheel')}</Kbd>,
            ],
            [
              t('Zoom in : '),
              <Kbd key="10">{t('Double left click')}</Kbd>,
              t('or'),
              <Kbd key="11">+</Kbd>,
            ],
            [
              t('Zoom out : '),
              <Kbd key="20">{t('Right click')}</Kbd>,
              t('or'),
              <Kbd key="21">-</Kbd>,
            ],
          ]}
        </FwHelpBox>
      </ChakraMapContainer>
    </Box>
  );
};

const mapPropTypes = {
  ...mapViewTypes,
  scrollWheelZoom: bool,
  dragging: bool,
  // onViewportChanged: func,
};

type MapProps = InferProps<typeof mapPropTypes>;

FwMap.propTypes = {
  maskStructure: (prop: FwMaskCommonProps) => {
    const { maskStructure } = prop;
    const { view } = maskStructure || {};
    const { center } = view || {};
    if (
      !Array.isArray(center) ||
      center.length != 2 ||
      !center.every(Number.isFinite)
    ) {
      return new Error(`[${center}] needs to be an array of two numbers`);
    }
  },
};

export default FwMap;
