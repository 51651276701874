import { InferProps } from 'prop-types';
import React, { FC, useState } from 'react';
// import utils from 'core/utils/utils';

import { PageStore, FwPageStoreContext } from './FwPageStoreContext';

const FwPageStoreProvider: FC<Props> = ({ children }) => {
  const [pageStore, setPageStore] = useState<PageStore>({});

  return (
    <FwPageStoreContext.Provider
      value={{
        pageStore,
        setPageStore,
      }}
    >
      {children}
    </FwPageStoreContext.Provider>
  );
};

const propTypes = {};

type Props = InferProps<typeof propTypes>;

FwPageStoreProvider.propTypes = propTypes;

export { FwPageStoreProvider };
