import utils from 'core/utils/utils';

const updateRefFieldDataRelated = (
  doc: any,
  docDataQuery,
  key,
  additionalData,
  docDataToUpdate?,
  idx?
) => {
  const docData = docDataToUpdate || docDataQuery;
  const endKeyQuery = idx ? `|${idx}` : '';
  const keyQuery = `${key}${endKeyQuery}`;

  // additionalData must be valid for type reference
  const { referenceSearchField, referenceFields, linkFields } =
    additionalData || {};

  const refLinkDoc =
    doc && doc.extendDocuments
      ? doc.extendDocuments.find((rld) =>
          utils.isStringDataHasFieldValue(
            rld.referenceDocument.data,
            referenceSearchField,
            docDataQuery[keyQuery]
          )
        )
      : undefined;

  docData[key] = {
    inputValue: docDataQuery[keyQuery],
    refDocID: refLinkDoc ? refLinkDoc.referenceDocument.documentID : undefined,
    linkDocID:
      refLinkDoc && refLinkDoc.linkDocument
        ? refLinkDoc.linkDocument.documentID
        : undefined,
  };

  // inject refDocData and linkDocData into docData
  if (refLinkDoc) {
    const { referenceDocument, linkDocument } = refLinkDoc;

    if (referenceFields) {
      const refDocData = JSON.parse(referenceDocument.data);

      referenceFields.forEach((rf) => {
        docData[`${key}|${rf}`] = refDocData[`${rf}`];
      });
    }

    if (linkFields) {
      const linkDocData = linkDocument
        ? JSON.parse(linkDocument.data)
        : undefined;

      linkFields.forEach((lf) => {
        const combineKey = `${key}|${lf}`;
        docData[combineKey] = linkDocData
          ? linkDocData[`${combineKey}${endKeyQuery}`]
          : '';
      });
    }
  }
};

export default updateRefFieldDataRelated;
