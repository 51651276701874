import { Grid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwCard, FwSpinner, FwContextMenu } from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';

import { formToCard } from './FwMask.Cards.helpers';

const FwCards: FC<FwMaskCommonProps> = ({
  maskStructure,
  maskRows,
  loading,
  handleOpen,
  processes,
  handleProcessActionClick,
}) => {
  const { t } = useTranslation();
  const [cards, setCards] = useState(null);

  useEffect(() => {
    if (maskStructure && maskRows) {
      setCards(_.map(maskRows, (mr) => formToCard(maskStructure, mr, t)));
    }
  }, [maskStructure, maskRows, t]);

  return (
    <Grid>
      {!loading && cards ? (
        _.map(cards, ({ key, title, color }, index) => {
          const id = key || index;
          const openFunc = () => handleOpen(id);

          // define context menu items
          const contextItems = getContextMenuItems(
            t,
            openFunc,
            () => handleOpen(id, true),
            processes,
            (processId) => handleProcessActionClick(processId, id)
          );

          return (
            <GridItem key={id} my={2}>
              <FwContextMenu
                renderTag="div"
                childrenRenderTag="div"
                items={contextItems}
              >
                <FwCard
                  borderColor={color}
                  content={_.map(title.split('\n'), (s) => [s])}
                  onClick={openFunc}
                />
              </FwContextMenu>
            </GridItem>
          );
        })
      ) : (
        <FwSpinner />
      )}
    </Grid>
  );
};

FwCards.propTypes = {};

export default FwCards;
