import React, { useState } from 'react';

import trackingApi from 'api/tracking/trackingApi';
import { useApi } from 'api/useApi';
import { FwSpinner, useFwArea, useFwAuth } from 'components/base';
import utils from 'core/utils/utils';

import TrackingByAddress from './TrackingByAddress';

const TrackingByAddressContainer = ({ anonymous }: any) => {
  // const authorized =
  //   anonymous || utils.canPerform(currentUser, 'trackbyaddress');

  // const { area } = useFwArea();
  // const { user: currentUser } = useFwAuth();

  // const [loading, setLoading] = useState(false);
  // const [address, setAddress] = useState({
  //   number: undefined,
  //   complement: undefined,
  //   street: undefined,
  //   city: undefined,
  // });
  // const [getArgs] = useState([area.key.toUpperCase()]);
  // const [trackArgs, setTrackArgs] = useState([]);

  // const { fetched: fetchedAddresses, pending: pendingFetch } = useApi(
  //   authorized ? trackingApi.getAddresses : undefined,
  //   getArgs
  // );
  // const { response: fetchedDate, pending: pendingDate } = useApi(
  //   authorized ? trackingApi.track : undefined,
  //   trackArgs
  // );

  // const handleChangeMunicipality = (e, data) => {
  //   setAddress({
  //     ...address,
  //     city: data.value,
  //     street: undefined,
  //     number: undefined,
  //   });
  // };

  // const handleChangeStreet = (e, data) => {
  //   setAddress({ ...address, street: data.value, number: undefined });
  // };

  // const handleChangeNumber = (e, { value }) => {
  //   const numberAndComplement = value.split(' - ');
  //   const number = numberAndComplement[0];
  //   const complement =
  //     numberAndComplement.length > 1 ? numberAndComplement[1] : undefined;

  //   const newAddress = {
  //     ...address,
  //     number: number,
  //     complement: complement,
  //   };

  //   setAddress(newAddress);
  //   setLoading(true);
  //   setTrackArgs([newAddress]);
  // };

  return null; /* authorized ? (
    pendingFetch || !fetchedAddresses ? (
      <FwSpinner />
    ) : (
      <TrackingByAddress
        addresses={fetchedAddresses.addresses}
        address={address}
        date={
          pendingDate || !fetchedDate
            ? undefined
            : fetchedDate?.data?.dateTimes[0]
        }
        ready={loading || pendingDate}
        handleChangeMunicipality={handleChangeMunicipality}
        handleChangeStreet={handleChangeStreet}
        handleChangeNumber={handleChangeNumber}
      />
    )
  ) : (
    <></>
  );*/
};

export default React.memo(TrackingByAddressContainer);
